import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useValidationErrorBlank } from 'hooks';
import React, { useEffect } from 'react';
import { Control, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form';

export interface IProfileLastNameField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getUsersSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfileLastNameField: React.FunctionComponent<
  IProfileLastNameField
> = ({
  control,
  errors,
  setValue,
  getSanityBaseLanguageSilently = false,
  getUsersSilently = false,
  getProfilesSilently = false,
}) => {
  const { profile, user } = useProfileForm({
    getSanityBaseLanguageSilently,
    getUsersSilently,
    getProfilesSilently,
  });

  const { getValidationErrorBlank } = useValidationErrorBlank();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const lastNameInput = baseLanguage.profile_fields.last_name_input_label;

  const defaultValue = profile?.lastName ?? user?.familyName;

  useEffect(() => {
    if (defaultValue) {
      setValue('lastName', defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="lastName"
      data-cy="forms_ProfileForm_lastName-field"
      control={control}
      errors={errors}
      label={lastNameInput}
      rules={{ required: true }}
      defaultValue={defaultValue}
      requiredErrorMessage={getValidationErrorBlank(lastNameInput)}
    />
  );
};

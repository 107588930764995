import { Control, FieldErrorsImpl, UseFormUnregister } from 'react-hook-form';
import { FC } from 'react';

import {
  ICustomField,
  IProfile,
  SanityBaseLanguage,
  SanityPersonalDetails,
  SanityProfileField,
} from '@guider-global/shared-types';

import { IOrganizationFieldValues } from 'forms/ProfileForms/ProfileForm';
import {
  CompleteProfilePronounsField,
  CompleteProfileDateOfBirthField,
  CompleteProfileGenderDropdown,
  CompleteProfileJobTitleField,
  CompleteProfileTownOrCityField,
  CompleteProfileCountryDropdown,
  CompleteProfileFormCustomFields,
  CompleteProfileFormLinkedInUrlField,
} from 'forms/ProfileForms/CompleteProfileForm/components';

import { sanityFieldToOptionsArray } from 'utils';
import CompleteProfileCustomLegalCheckboxes, {
  OrganizationAgreement,
} from './components/CompleteProfileCustomLegalCheckboxes';
import { useValidationErrorBlank } from 'hooks';

export interface CompleteProfileFormProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister: UseFormUnregister<IOrganizationFieldValues>;
  organizationName: string;
  sanityBaseLanguage: SanityBaseLanguage;
  profile: IProfile;
  sanityOrganizationProfileFields: SanityProfileField[];
  sanityOrganizationPersonalDetails: SanityPersonalDetails;
  organizationAgreements: OrganizationAgreement[];
  loading?: boolean;
}

const CompleteProfileForm: FC<CompleteProfileFormProps> = ({
  control,
  errors,
  unregister,
  organizationName,
  sanityBaseLanguage,
  profile,
  sanityOrganizationProfileFields,
  sanityOrganizationPersonalDetails,
  organizationAgreements,
  loading,
}) => {
  const baseLanguageProfileFields = sanityBaseLanguage.profile_fields;
  const baseLanguageLegalNotices = sanityBaseLanguage.legal_notices;

  const {
    linkedin_url: linkedInUrlBaseLanguage,
    gender: genderBaseLanguage,
    country: countryBaseLanguage,
  } = baseLanguageProfileFields;
  // Base Languages
  const sanityBaseLanguageLegalNoticesLabel =
    baseLanguageLegalNotices.i_agree_to_legals_label;

  const { getValidationErrorBlank } = useValidationErrorBlank();

  return (
    <>
      <CompleteProfilePronounsField
        control={control}
        errors={errors}
        unregister={unregister}
        label={baseLanguageProfileFields.pronouns_input_label}
        value={profile.pronouns}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.pronouns_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.pronouns.enabled}
        required={sanityOrganizationPersonalDetails.pronouns.required}
      />
      <CompleteProfileDateOfBirthField
        control={control}
        errors={errors}
        unregister={unregister}
        label={baseLanguageProfileFields.date_of_birth_input_label}
        value={profile.dateOfBirth}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.date_of_birth_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.date_of_birth.enabled}
        required={sanityOrganizationPersonalDetails.date_of_birth.required}
      />
      <CompleteProfileGenderDropdown
        control={control}
        errors={errors}
        unregister={unregister}
        label={genderBaseLanguage.gender_input_label}
        value={profile.gender}
        errorMessage={genderBaseLanguage.gender_validation_error_not_set}
        options={sanityFieldToOptionsArray(genderBaseLanguage.options)}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.gender.enabled}
        required={sanityOrganizationPersonalDetails.gender.required}
      />
      <CompleteProfileJobTitleField
        control={control}
        errors={errors}
        unregister={unregister}
        label={
          sanityOrganizationPersonalDetails.job_title?.custom_label ??
          baseLanguageProfileFields.job_title_input_label
        }
        value={profile.jobTitle}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.job_title_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.job_title.enabled}
        required={sanityOrganizationPersonalDetails.job_title.required}
      />
      <CompleteProfileTownOrCityField
        control={control}
        errors={errors}
        unregister={unregister}
        label={baseLanguageProfileFields.town_or_city_input_label}
        value={profile.townOrCity}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.town_or_city_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.town_or_city.enabled}
        required={sanityOrganizationPersonalDetails.town_or_city.required}
      />
      <CompleteProfileCountryDropdown
        control={control}
        errors={errors}
        unregister={unregister}
        label={
          sanityOrganizationPersonalDetails.country?.custom_label ??
          countryBaseLanguage.country_input_label
        }
        value={profile.country}
        errorMessage={countryBaseLanguage.country_validation_error_not_set}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.country.enabled}
        required={sanityOrganizationPersonalDetails.country.required}
      />
      <CompleteProfileFormLinkedInUrlField
        control={control}
        errors={errors}
        unregister={unregister}
        label={linkedInUrlBaseLanguage.input_label}
        value={profile.linkedInUrl}
        validateErrorMessage={linkedInUrlBaseLanguage.validation_error_invalid}
        requiredErrorMessage={getValidationErrorBlank(
          linkedInUrlBaseLanguage.input_label,
        )}
        loading={loading}
        required={sanityOrganizationPersonalDetails.linkedin_url.required}
        enabled={sanityOrganizationPersonalDetails.linkedin_url.enabled}
      />
      {/* Custom Profile fields */}
      <CompleteProfileFormCustomFields
        control={control}
        errors={errors}
        profileOrganizationFields={profile.organizationFields as ICustomField[]}
        sanityOrganizationProfileFields={sanityOrganizationProfileFields}
        loading={loading}
      />
      {/* Legal Agreements */}
      <CompleteProfileCustomLegalCheckboxes
        control={control}
        errors={errors}
        organizationAgreements={organizationAgreements}
        header={`${sanityBaseLanguageLegalNoticesLabel} ${organizationName}'s`}
        loading={loading}
      />
    </>
  );
};

export default CompleteProfileForm;

import { Skeleton, Typography } from '@mui/material';

import { FunctionComponent } from 'react';
import { FieldErrorsImpl, Control } from 'react-hook-form';

import { IOrganizationFieldValues } from 'forms';

import ProfileFormCustomLegalCheckbox, {
  ProfileFormCustomLegalCheckboxProps,
} from '../../../components/ProfileFormCustomLegalCheckbox';

export interface OrganizationAgreement
  extends Omit<ProfileFormCustomLegalCheckboxProps, 'control' | 'errors'> {
  id: string;
}

export interface CompleteProfileCustomLegalCheckboxesProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  organizationAgreements: OrganizationAgreement[];
  header: string;
  loading?: boolean;
}

const CompleteProfileCustomLegalCheckboxes: FunctionComponent<
  CompleteProfileCustomLegalCheckboxesProps
> = ({ control, errors, organizationAgreements, header, loading }) => {
  const filteredIncompleteAgreements = organizationAgreements.filter(
    (agreement) => agreement.defaultValue === 'false',
  );

  if (filteredIncompleteAgreements.length === 0) {
    return <></>;
  }
  if (loading) {
    return <Skeleton variant="rectangular" sx={{ height: '56px', mt: 2.5 }} />;
  }
  return (
    <>
      <Typography
        sx={{
          mt: 2.5,
        }}
        variant="body1"
        color="text.secondary"
      >
        {header}
      </Typography>
      {filteredIncompleteAgreements.map((agreement, index) => {
        const { id, ...props } = agreement;
        return (
          <ProfileFormCustomLegalCheckbox
            key={`${agreement.id}-${agreement.customCheckboxName}`}
            control={control}
            errors={errors}
            {...props}
            data-cy="forms_profileForms_components_profileFormCustomLegalCheckbox"
          />
        );
      })}
    </>
  );
};
export default CompleteProfileCustomLegalCheckboxes;

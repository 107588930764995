import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useValidationErrorBlank } from 'hooks';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrorsImpl,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';

export interface IProfileDateOfBirthField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfileDateOfBirthField: React.FunctionComponent<
  IProfileDateOfBirthField
> = ({
  control,
  errors,
  unregister,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  const { profile, sanityOrganizationPersonalDetailsFields } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
  });

  const { getValidationErrorBlank } = useValidationErrorBlank();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const dateOfBirthInput =
    baseLanguage.profile_fields.date_of_birth_input_label;

  const defaultValue = profile?.dateOfBirth;

  useEffect(() => {
    if (defaultValue) {
      setValue('dateOfBirth', defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="dateOfBirth"
      data-cy="forms_ProfileForm_dateOfBirth-field"
      control={control}
      errors={errors}
      label={dateOfBirthInput}
      rules={{
        required:
          sanityOrganizationPersonalDetailsFields.date_of_birth.required,
      }}
      defaultValue={defaultValue}
      requiredErrorMessage={getValidationErrorBlank(dateOfBirthInput)}
      enabled={sanityOrganizationPersonalDetailsFields.date_of_birth.enabled}
      unregister={unregister}
      type="date"
      InputLabelProps={{ shrink: true }}
      inputProps={{
        max: '9999-12-31',
      }}
      sx={{
        'div > div': {
          display: 'none',
        },
      }}
    />
  );
};

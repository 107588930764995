import { FunctionComponent } from 'react';
import { FieldErrorsImpl, Control } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import { IOrganizationFieldValues } from 'forms';
import { FormTextFieldTyped } from 'components';

export interface EditProfileLastNameFieldProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  label: string;
  value: string;
  errorMessage: string;
  loading?: boolean;
}

export const EditProfileLastNameField: FunctionComponent<
  EditProfileLastNameFieldProps
> = ({ control, errors, label, value, errorMessage, loading }) => {
  if (loading) {
    return <Skeleton variant="rectangular" sx={{ height: '56px', mt: 2.5 }} />;
  }
  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="lastName"
      data-cy="forms_ProfileEditForm_lastName-field"
      control={control}
      errors={errors}
      label={label}
      rules={{ required: false }}
      defaultValue={value}
      requiredErrorMessage={errorMessage}
    />
  );
};

/* eslint-disable camelcase */
import { FC, useEffect } from 'react';
import { Control, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form';

import { SanityFreeTextConfig, SanitySlug } from '@guider-global/shared-types';

import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms/ProfileForms/ProfileForm';
import { ProfileFormsCustomField } from '..';

interface CustomFreeTextFieldProps {
  field: {
    free_text_config: SanityFreeTextConfig;
    id: SanitySlug;
    required: boolean;
    text: ProfileFormsCustomField['text'];
    defaultValue?: string | string[] | boolean;
  };
  setValue?: UseFormSetValue<IOrganizationFieldValues>;
  name: string;
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
}

const CustomFreeTextField: FC<CustomFreeTextFieldProps> = ({
  field,
  setValue,
  name,
  control,
  errors,
}) => {
  //  Derivation
  const {
    free_text_config,
    id: { current: id },
    required,
    text,
    defaultValue,
  } = field;
  const { minimum_length, maximum_length, text_area_size } = free_text_config;
  const { label, required_validation_error_text, assistive_text } = text;
  const rows = text_area_size === 'singleline' ? 1 : 4;

  useEffect(() => {
    if (!defaultValue || !id) return;
    setValue && setValue(id, defaultValue);
  }, [defaultValue, id, setValue]);

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      key={name}
      name={id}
      control={control}
      errors={errors}
      rules={{
        required,
        minLength: minimum_length,
        maxLength: maximum_length,
      }}
      defaultValue={defaultValue}
      label={label}
      requiredErrorMessage={required_validation_error_text}
      helperText={assistive_text}
      rows={rows}
      data-cy={`forms_profileForms_components_profileFormsCustomFields_${name}`}
    />
  );
};

export default CustomFreeTextField;

import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useValidationErrorBlank } from 'hooks';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrorsImpl,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';

export interface IProfilePronounsField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getUsersSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfilePronounsField: React.FunctionComponent<
  IProfilePronounsField
> = ({
  control,
  errors,
  unregister,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getUsersSilently = false,
  getProfilesSilently = false,
}) => {
  const { profile, sanityOrganizationPersonalDetailsFields } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getUsersSilently,
    getProfilesSilently,
  });

  const { getValidationErrorBlank } = useValidationErrorBlank();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const pronounsField = baseLanguage.profile_fields.pronouns_input_label;

  const defaultValue = profile?.pronouns;

  useEffect(() => {
    if (defaultValue) {
      setValue('pronouns', defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="pronouns"
      data-cy="forms_ProfileForm_pronouns-field"
      control={control}
      errors={errors}
      label={pronounsField}
      rules={{
        required: sanityOrganizationPersonalDetailsFields.pronouns.required,
      }}
      defaultValue={defaultValue}
      requiredErrorMessage={getValidationErrorBlank(pronounsField)}
      enabled={sanityOrganizationPersonalDetailsFields.pronouns.enabled}
      unregister={unregister}
    />
  );
};

import { useSettings } from 'hooks';
import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import { SettingsAccountPreferencesFields } from 'forms/AccountPreferencesForm';
import { datadogLogs } from '@guider-global/datadog';

export function useAccountPreferencesForm() {
  const { getSettings, updateSettings } = useSettings({});
  const [settings] = getSettings();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<SettingsAccountPreferencesFields>({
    mode: 'onChange',
    values: {
      timezone: settings?.timezone,
    },
  });

  const onSubmit = async () => {
    const { timezone } = getValues();
    await updateSettings(settings.id, { timezone });
  };

  const onError: SubmitErrorHandler<SettingsAccountPreferencesFields> = (
    errors: FieldErrorsImpl<SettingsAccountPreferencesFields>,
  ) => {
    datadogLogs.logger.error('AccountPreferencesForm:onSubmit', { errors });
    console.error(errors);
  };

  return {
    control,
    errors,
    handleSubmit,
    onSubmit,
    onError,
    isSubmitting,
    isDirty,
    isValid,
  };
}

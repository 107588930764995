import { IMembership } from '@guider-global/shared-types';
import PersonPinSharp from '@mui/icons-material/PersonPinSharp';
import { useTheme } from '@mui/material';
import { PageCard, PageCheckbox } from 'components';
import { useMobileMediaQuery } from 'hooks';
import { BaseReactHookFormComponentProps } from 'pages/Programs/ProgramManageGuidePage';

export interface PublishProfileProps extends BaseReactHookFormComponentProps {
  guideMembership: IMembership;
  profilePublishHeading: string;
  profilePublishDescription: string;
  profilePendingHeading: string;
  profilePendingDescription: string;
}

export const PublishProfile = ({
  control,
  errors,
  guideMembership,
  profilePublishHeading,
  profilePublishDescription,
  profilePendingHeading,
  profilePendingDescription,
}: PublishProfileProps) => {
  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const requiresApproval = guideMembership?.requiresApproval;

  if (requiresApproval) {
    return (
      <PageCard
        title={profilePendingHeading}
        subtitle={profilePendingDescription}
        color="warning"
        icon={PersonPinSharp}
        textAlign={isMobile ? 'center' : 'start'}
        cardSx={{
          border: `1px solid ${theme.palette.warning.main} `,
          mx: 0,
          mb: 3,
          flexDirection: 'row',
        }}
        iconSx={{
          fontSize: 30,
          mr: 2,
        }}
      />
    );
  }

  return (
    <PageCheckbox
      control={control}
      name="isPublished"
      errors={errors}
      defaultValue={guideMembership.isPublished}
      heading={profilePublishHeading}
      description={profilePublishDescription}
      dataCy="forms_programManageGuideForm_publishProfile_checkbox"
    />
  );
};

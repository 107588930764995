import React from 'react';
import { FieldErrorsImpl, Control } from 'react-hook-form';

import { ICustomField, SanityProfileField } from '@guider-global/shared-types';

import { IOrganizationFieldValues } from 'forms';
import { Skeleton } from '@mui/material';
import { ProfileFormsCustomFields } from 'forms/ProfileForms/components/ProfileFormsCustomFields';

export interface EditProfileFormCustomFieldsProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  organizationFields: ICustomField[];
  sanityOrganizationProfileFields: SanityProfileField[];
  loading?: boolean;
}

interface CustomField extends SanityProfileField {
  defaultValue?: string | string[] | boolean;
}

export const EditProfileFormCustomFields: React.FunctionComponent<
  EditProfileFormCustomFieldsProps
> = ({
  control,
  errors,
  organizationFields,
  sanityOrganizationProfileFields,
  loading,
}) => {
  const profileValues = organizationFields;

  if (!sanityOrganizationProfileFields) return null;
  if (loading) {
    return <Skeleton variant="rectangular" sx={{ height: '56px', mt: 2.5 }} />;
  }

  const customFields: CustomField[] = sanityOrganizationProfileFields.map(
    (customField) => {
      const defaultValues = profileValues.filter(
        (value) => value.fieldSlug === customField.id.current,
      );

      const defaultValue =
        defaultValues.length > 0 ? defaultValues[0].value : undefined;

      return {
        ...customField,
        defaultValue,
      };
    },
  );
  return (
    <ProfileFormsCustomFields
      control={control}
      errors={errors}
      customFields={customFields}
    />
  );
};

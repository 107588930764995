import { useContext } from 'react';
import { Box, useTheme } from '@mui/system';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import {
  SanityProgramSurveyField,
  SurveyType,
} from '@guider-global/shared-types';
import { getSubDomain } from '@guider-global/front-end-utils';
import { Button, InfoIcon, Stack, Text } from '@guider-global/ui';
import {
  RatingInputValue,
  SelectInputValue,
  SurveyFormRating,
  SurveyFormSelect,
  SurveyFormTextarea,
  TextareaInputValue,
} from './components';
import { useSurveys } from 'hooks';
import { datadogLogs } from '@guider-global/datadog';
import { SurveyModalsContext } from 'containers/Surveys/SurveyModalsContext';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

export interface SurveyFormProps {
  title: string;
  programSlug: string;
  profile?: string;
  type: SurveyType;
  session?: string;
  relationship?: string;
  surveyFields: SanityProgramSurveyField[];
  isGuide: boolean;
}

export interface SurveyFormFields {
  [key: string]: RatingInputValue | SelectInputValue | TextareaInputValue;
}

export function SurveyForm({
  profile,
  surveyFields,
  isGuide,
  ...restProps
}: Readonly<SurveyFormProps>) {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const disclaimerText =
    baseLanguage.surveys?.feedback_requested_modal?.survey_information;

  const { palette } = useTheme();
  const organizationSlug = getSubDomain();
  const { getIsLoadingSurveys, createSurveys, reqSurveys } = useSurveys({});
  const isLoadingSurveys = getIsLoadingSurveys();

  const { setIsSurveySubmitted } = useContext(SurveyModalsContext);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<SurveyFormFields>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<SurveyFormFields> = async (data) => {
    if (!profile) {
      return;
    }

    const surveyFields = Object.entries(data).map(([key, value]) => ({
      fieldSlug: key,
      fieldType: value?.fieldType,
      value: value?.value,
    }));
    const payload = {
      organizationSlug,
      profile,
      surveyFields,
      ...restProps,
    };

    await createSurveys(payload);
    await reqSurveys({ url: '/surveys' });

    setIsSurveySubmitted(true);
  };

  const onError: SubmitErrorHandler<SurveyFormFields> = (errors) => {
    datadogLogs.logger.error('SurveyForm:onSubmit', { errors });
    console.error(errors);
  };

  const fieldComponents = surveyFields.map((surveyField) => {
    const key = surveyField.slug.current;
    const fieldProps = { key, control, surveyField, isGuide };

    switch (surveyField.field_type) {
      case 'text':
        return <SurveyFormTextarea {...fieldProps} />;
      case 'select':
        return <SurveyFormSelect {...fieldProps} />;
      case 'scale':
        return <SurveyFormRating {...fieldProps} />;
      default:
        return <></>;
    }
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *:not(:last-child)': { marginBottom: 3 },
        '& > *:nth-last-child(2)': { marginBottom: 0 },
      }}
    >
      {fieldComponents}
      {disclaimerText && (
        <Stack direction={'row'} spacing={1}>
          <InfoIcon size="small" />
          <Text
            color={palette.text.secondary}
            variant="body1"
            text={disclaimerText}
          />
        </Stack>
      )}

      <Box
        sx={{
          paddingY: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'sticky',
          left: 0,
          bottom: 0,
          backgroundColor: 'paper.background.primary',
        }}
      >
        <Button
          variant={'contained'}
          loading={isLoadingSurveys}
          disabled={!isValid}
          label={'Submit'}
          type="submit"
          color="info"
        />
      </Box>
    </Box>
  );
}

import { Typography } from '@mui/material';
import {
  ICustomField,
  SanityAdditionalAgreement,
} from '@guider-global/shared-types';
import { FunctionComponent } from 'react';
import { FieldErrorsImpl, Control } from 'react-hook-form';

import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { IOrganizationFieldValues } from 'forms';

import ProfileFormCustomLegalCheckbox from '../../../components/ProfileFormCustomLegalCheckbox';
import { getAgreementNames } from 'utils';

export interface ProfileFormCustomLegalCheckboxesProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
}

const ProfileFormCustomLegalCheckboxes: FunctionComponent<
  ProfileFormCustomLegalCheckboxesProps
> = ({
  control,
  errors,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  // Hooks
  const {
    sanityOrganizationBasicInfo,
    sanityBaseLanguageLegalNotices,
    sanityOrganizationWhiteLabel,
    profile,
  } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
  });
  // - White Label
  const additionalAgreements =
    sanityOrganizationWhiteLabel.additional_agreements ?? [];

  // - Organization
  const organizationName = sanityOrganizationBasicInfo.name;
  const organizationFields =
    profile && (profile.organizationFields as ICustomField[]);

  // - Base Language Legal notices
  const sanityBaseLanguageLegalNoticesLabel =
    sanityBaseLanguageLegalNotices.i_agree_to_legals_label;

  // Events
  const getAgreementProps = (
    agreement: SanityAdditionalAgreement,
    control: Control<IOrganizationFieldValues>,
  ) => {
    const {
      name,
      agreement_name: { current },
      _key,
    } = agreement;

    const { customCheckboxName, slug } = getAgreementNames({
      name: current,
      id: _key,
    });

    const getDefaultValue = () => {
      if (!organizationFields) return 'false';
      const customFields = organizationFields.filter(
        (field) => field.fieldSlug === slug,
      );
      if (customFields.length > 0) return customFields[0].value as string;
      return 'false';
    };

    return {
      control,
      errors,
      customCheckboxName,
      organizationName,
      label: name,
      defaultValue: getDefaultValue(),
    };
  };

  if (additionalAgreements.length === 0) {
    return <></>;
  }

  return (
    <>
      <Typography
        sx={{
          mt: 2.5,
        }}
        variant="body1"
        color="text.secondary"
      >
        {`${sanityBaseLanguageLegalNoticesLabel} ${organizationName}'s`}
      </Typography>
      {additionalAgreements.map((agreement) => {
        const checkBoxProps = getAgreementProps(agreement, control);
        return (
          <ProfileFormCustomLegalCheckbox
            key={`${agreement.name}-${checkBoxProps.customCheckboxName}`}
            {...checkBoxProps}
          />
        );
      })}
    </>
  );
};
export default ProfileFormCustomLegalCheckboxes;

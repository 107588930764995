// external
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Button } from '@mui/material';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

// components
import { Asterisk, FormDropdownTyped, FormTextFieldTyped } from 'components';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useAppSelector } from 'store/hooks';

// types
import { IGoal } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

interface IGoalsEditFormProps {
  goal: IGoal;
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  isDirty: boolean;
  isLoadingGoals: boolean;
  discardAction: () => void;
  hideButtons?: boolean;
}

export const GoalsEditForm: React.FC<IGoalsEditFormProps> = ({
  goal,
  handleSubmit,
  control,
  errors,
  isValid,
  isDirty,
  isLoadingGoals,
  discardAction,
  hideButtons,
}) => {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const isMobile = useMobileMediaQuery();
  const goalCategories = useAppSelector((state) =>
    state.sanityOrganization.organization.goal_categories.goal_categories.map(
      (goalCategory) => ({
        label: goalCategory.goal_category_name,
        value: goalCategory.goal_category_slug.current,
      }),
    ),
  );
  const goalStatuses = Object.entries(baseLanguage.goals.goal_statuses).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6">
        {`1. ${baseLanguage.goals.create_and_update_goal.goal_fields.summary.category_select_label}`}
        <Asterisk />
      </Typography>
      <FormDropdownTyped
        name="categorySlug"
        control={control}
        label={
          baseLanguage.goals.create_and_update_goal.goal_fields.summary
            .category_select_label
        }
        errors={errors}
        options={goalCategories}
        required
        defaultValue={goal?.categorySlug}
        data-cy="forms_GoalsEditForm_dropdown"
      />
      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`2. ${baseLanguage.goals.create_and_update_goal.goal_fields.summary.objective_input_label}`}
      </Typography>
      <FormTextFieldTyped
        data-cy="forms_GoalsEditForm_objective"
        name="objective"
        control={control}
        label={
          baseLanguage.goals.create_and_update_goal.goal_fields.summary
            .objective_input_label
        }
        errors={errors}
        defaultValue={goal?.objective ?? ''}
      />
      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`3. ${baseLanguage.goals.create_and_update_goal.goal_fields.summary.status_input_label}`}
      </Typography>
      <FormDropdownTyped
        data-cy="forms_GoalsEditForm_status"
        name="status"
        control={control}
        label={
          baseLanguage.goals.create_and_update_goal.goal_fields.summary
            .status_input_label
        }
        errors={errors}
        options={goalStatuses}
        defaultValue={goal?.status}
      />
      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`4. ${baseLanguage.goals.create_and_update_goal.goal_fields.outcomes.title}`}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" fontWeight={400}>
        {
          baseLanguage.goals.create_and_update_goal.goal_fields.outcomes
            .description
        }
      </Typography>
      <FormTextFieldTyped
        name="outcomes"
        control={control}
        label={
          baseLanguage.goals.create_and_update_goal.goal_fields.outcomes.title
        }
        errors={errors}
        rows={2}
        defaultValue={goal.outcomes ?? ''}
      />
      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`5. ${baseLanguage.goals.create_and_update_goal.goal_fields.benefits.title}`}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" fontWeight={400}>
        {
          baseLanguage.goals.create_and_update_goal.goal_fields.benefits
            .description
        }
      </Typography>
      <FormTextFieldTyped
        name="rationale"
        control={control}
        label={
          baseLanguage.goals.create_and_update_goal.goal_fields.benefits.title
        }
        errors={errors}
        rows={2}
        defaultValue={goal.rationale ?? ''}
      />
      {!hideButtons && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <Button
            data-cy="forms-GoalsEditForm_discard-button"
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            onClick={discardAction}
          >
            {baseLanguage.globals.common.discard_changes_button_label}
          </Button>
          <LoadingButton
            data-cy="forms-GoalsEditForm_submit-button"
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid || !isDirty}
            sx={{
              color: isValid ? 'white' : 'inherit',
              mb: isMobile ? 2 : 0,
            }}
            fullWidth={isMobile}
            loading={isLoadingGoals}
          >
            {
              baseLanguage.goals.create_and_update_goal.update_goal
                .update_goal_button_label
            }
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};

export default GoalsEditForm;

import React, { useEffect } from 'react';
import {
  FieldErrorsImpl,
  Control,
  UseFormUnregister,
  UseFormSetValue,
} from 'react-hook-form';
import { IOrganizationFieldValues } from 'forms';
import { sanityFieldToOptionsArray } from 'utils';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { FormDropdownTyped } from 'components';

export interface IProfileGenderDropdown {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfileGenderDropdown: React.FunctionComponent<
  IProfileGenderDropdown
> = ({
  control,
  errors,
  unregister,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  const {
    profile,
    sanityOrganizationPersonalDetailsFields,
    sanityBaseLanguageProfileFields,
  } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
  });

  const defaultValue = profile?.gender;

  useEffect(() => {
    if (defaultValue) {
      setValue('gender', defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <FormDropdownTyped<IOrganizationFieldValues, string>
      name="gender"
      data-cy="forms_ProfileForm_gender-field"
      control={control}
      errors={errors}
      emptyOption={true}
      label={sanityBaseLanguageProfileFields.gender.gender_input_label}
      requiredErrorMessage={
        sanityBaseLanguageProfileFields.gender.gender_validation_error_not_set
      }
      options={sanityFieldToOptionsArray(
        sanityBaseLanguageProfileFields.gender.options,
      )}
      enabled={sanityOrganizationPersonalDetailsFields.gender.enabled}
      unregister={unregister}
      defaultValue={defaultValue}
    ></FormDropdownTyped>
  );
};

/* eslint-disable camelcase */
import { FC, useEffect } from 'react';
import { Control, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form';

import {
  SanityFieldType,
  SanityMultiSelectConfig,
  SanitySelectOption,
  SanitySlug,
} from '@guider-global/shared-types';

import { FormAutocomplete } from 'components';
import { IOrganizationFieldValues } from 'forms/ProfileForms/ProfileForm';
import { ProfileFormsCustomField } from '..';

interface CustomSelectFieldsProps {
  field: {
    multi_select_config: SanityMultiSelectConfig;
    options: SanitySelectOption[];
    required: boolean;
    text: ProfileFormsCustomField['text'];
    type: SanityFieldType;
    defaultValue?: string | string[] | boolean;
    id: SanitySlug;
  };
  setValue?: UseFormSetValue<IOrganizationFieldValues>;
  name: string;
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
}

const CustomSelectFields: FC<CustomSelectFieldsProps> = ({
  field,
  setValue,
  name,
  control,
  errors,
}) => {
  // Prop Derivation
  const {
    multi_select_config,
    options,
    required,
    text,
    type,
    defaultValue,
    id: { current: id },
  } = field;
  const {
    maximum_selections,
    minimum_selections,
    text: errorText,
  } = multi_select_config;
  const { label, required_validation_error_text, assistive_text } = text;

  const minimumSelectionsNotMetValidationError =
    errorText?.minimum_selections_not_met_validation_error ?? '';
  const maximumSelectionsExceededValidationError =
    errorText?.maximum_selections_exceeded_validation_error ?? '';

  // Events
  const multiSelectValidate = (value: unknown) => {
    if (type === 'multi-select') {
      const valueLength = (value as string[]).length;
      if (valueLength > maximum_selections)
        return maximumSelectionsExceededValidationError;
      if (valueLength < minimum_selections)
        return minimumSelectionsNotMetValidationError;
      return true;
    }
  };
  // Derivation
  const defaultNullValue = type === 'select' ? '' : [];

  useEffect(() => {
    if (!defaultValue || !id) return;

    setValue && setValue(id, defaultValue);
  }, [defaultValue, id, setValue]);

  return (
    <FormAutocomplete<IOrganizationFieldValues, string[]>
      name={name}
      control={control}
      errors={errors}
      rules={{
        required,
        validate: multiSelectValidate,
      }}
      defaultValue={defaultValue ?? defaultNullValue}
      label={label}
      emptyOption={type !== 'multi-select'}
      requiredErrorMessage={required_validation_error_text}
      options={options.map((option) => {
        return {
          value: option.id.current,
          label: option.label,
        };
      })}
      helperText={assistive_text}
      multiple={type === 'multi-select'}
      data-cy={`forms_profileForms_components_profileFormsCustomFields_customSelectFields_${name}`}
    />
  );
};

export default CustomSelectFields;

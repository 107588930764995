import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useValidationErrorBlank } from 'hooks';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrorsImpl,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';

export interface IProfileTownOrCityField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfileTownOrCityField: React.FunctionComponent<
  IProfileTownOrCityField
> = ({
  control,
  errors,
  unregister,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  const { profile, sanityOrganizationPersonalDetailsFields } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
  });

  const { getValidationErrorBlank } = useValidationErrorBlank();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const townOrCityField = baseLanguage.profile_fields.town_or_city_input_label;

  const defaultValue = profile?.townOrCity;

  useEffect(() => {
    if (defaultValue) {
      setValue('townOrCity', defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="townOrCity"
      data-cy="forms_ProfileForm_townOrCity-field"
      control={control}
      errors={errors}
      label={townOrCityField}
      rules={{
        required: sanityOrganizationPersonalDetailsFields.town_or_city.required,
      }}
      defaultValue={defaultValue}
      requiredErrorMessage={getValidationErrorBlank(townOrCityField)}
      enabled={sanityOrganizationPersonalDetailsFields.town_or_city.enabled}
      unregister={unregister}
    />
  );
};

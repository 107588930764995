import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useValidationErrorBlank } from 'hooks';
import { FunctionComponent, useEffect } from 'react';
import {
  Control,
  FieldErrorsImpl,
  UseFormSetValue,
  UseFormUnregister,
  Validate,
} from 'react-hook-form';

export interface ProfileFormLinkedInUrlFieldProps {
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
}

export const ProfileFormLinkedInUrlField: FunctionComponent<
  ProfileFormLinkedInUrlFieldProps
> = ({ control, errors, unregister, setValue }) => {
  const {
    profile,
    sanityBaseLanguageProfileFields,
    sanityOrganizationPersonalDetailsFields,
  } = useProfileForm({});
  const defaultValue = profile?.linkedInUrl;
  const linkedInUrl = sanityBaseLanguageProfileFields.linkedin_url;

  useEffect(() => {
    if (defaultValue) {
      setValue('linkedInUrl', defaultValue);
    }
  }, [defaultValue, setValue]);

  const isRequired =
    sanityOrganizationPersonalDetailsFields.linkedin_url.required ?? false;

  const isEnabled =
    sanityOrganizationPersonalDetailsFields.linkedin_url.enabled ?? false;

  const validateUrl:
    | Validate<
        string | boolean | string[] | undefined,
        IOrganizationFieldValues
      >
    | Record<
        string,
        Validate<
          string | boolean | string[] | undefined,
          IOrganizationFieldValues
        >
      >
    | undefined = (value) => {
    if (!value) return !isRequired;
    if (typeof value !== 'string') return false;
    return value.includes('linkedin.com/');
  };

  const { getValidationErrorBlank } = useValidationErrorBlank();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const linkedinUrlField = baseLanguage.profile_fields.linkedin_url.input_label;

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="linkedInUrl"
      data-cy="forms_ProfileForm_linkedinUrl-field"
      control={control}
      errors={errors}
      validateErrorMessage={linkedInUrl.validation_error_invalid}
      requiredErrorMessage={getValidationErrorBlank(linkedinUrlField)}
      label={linkedinUrlField}
      rules={{
        required: isRequired,
        validate: {
          validateUrl,
        },
      }}
      enabled={isEnabled}
      unregister={unregister}
      defaultValue={defaultValue}
    />
  );
};

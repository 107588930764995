import { ICustomField, IError } from '@guider-global/shared-types';
import {
  IOrganizationFieldValues,
  IProfileFieldValues,
} from 'forms/ProfileForms/ProfileForm/types';
import { updateCustomFields, updateProfile } from 'forms/ProfileForms/utils';
import { useCustomFields, useProfiles } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { getSubDomain } from '@guider-global/front-end-utils';
import { UseCompleteProfileFormResults } from '../../types';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { getAgreementNames } from 'utils';
import { OrganizationAgreement } from '../../components/CompleteProfileCustomLegalCheckboxes';
import { useNavigate } from 'react-router-dom';

const useCompleteProfileForm = (): UseCompleteProfileFormResults => {
  const navigate = useNavigate();
  const organizationSlug: string = getSubDomain();
  // Hooks
  // - Sanity base language
  const {
    isSuccessSanityBaseLanguage,
    isLoadingSanityBaseLanguage,
    getBaseLanguage,
    getErrorsSanityBaseLanguage,
  } = useSanityBaseLanguage({});
  const loadingSanityBaseLanguage = isLoadingSanityBaseLanguage();
  const successSanityBaseLanguage = isSuccessSanityBaseLanguage();
  const errorsSanityBaseLanguage = getErrorsSanityBaseLanguage();
  const sanityBaseLanguage = getBaseLanguage();

  const localeCode = sanityBaseLanguage.locale_code.current;
  const errorAlertText =
    sanityBaseLanguage.settings.profile.profile_details.server_error_alert_text;

  // - Sanity organization
  const {
    isSuccessSanityOrganization,
    isLoadingSanityOrganization,
    getErrorsSanityOrganization,
    getOrganization,
  } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const successSanityOrganization = isSuccessSanityOrganization();
  const loadingSanityOrganization = isLoadingSanityOrganization();
  const errorsSanityOrganization = getErrorsSanityOrganization();
  const organization = getOrganization();
  const sanityOrganizationProfileFields = organization.profile_fields ?? [];
  const sanityOrganizationPersonalDetails = organization.personal_details;
  const organizationWhiteLabel = organization.white_label;
  const organizationName = organization.basic_info.name;

  const organizationLogo =
    organizationWhiteLabel && organizationWhiteLabel.logo;

  // - Profiles
  const {
    getProfiles,
    reqProfiles,
    isLoadingProfiles,
    isSuccessProfiles,
    getErrorsProfiles,
  } = useProfiles({});
  const successProfiles = isSuccessProfiles();
  const loadingProfiles = isLoadingProfiles();
  const errorsProfiles = getErrorsProfiles();
  const [profile] = getProfiles();
  const profileId = profile?.id;

  // - CustomFields
  const {
    reqCustomFields,
    isSuccessCustomFields,
    isLoadingCustomFields,
    getErrorsCustomFields,
  } = useCustomFields({});
  const successCustomFields = isSuccessCustomFields();
  const loadingCustomFields = isLoadingCustomFields();
  const errorsCustomFields = getErrorsCustomFields();

  // Agreements
  const additionalAgreements =
    organization.white_label?.additional_agreements ?? [];

  const getOrganizationAgreements = (): OrganizationAgreement[] => {
    const organizationFields =
      profile && (profile.organizationFields as ICustomField[]);

    const agreements: OrganizationAgreement[] = additionalAgreements.map(
      (agreement) => {
        const {
          name,
          agreement_name: { current },
          _key,
        } = agreement;

        const { customCheckboxName, slug } = getAgreementNames({
          name: current,
          id: _key,
        });

        const getDefaultValue = () => {
          const customFields = organizationFields.filter(
            (field) => field.fieldSlug === slug,
          );
          if (customFields.length > 0) return customFields[0].value as string;
          return 'false';
        };

        return {
          id: _key,
          customCheckboxName,
          label: name,
          defaultValue: getDefaultValue(),
        };
      },
    );
    return agreements;
  };

  const organizationAgreements = getOrganizationAgreements();
  // - Form
  const { control, handleSubmit, formState, unregister, setValue, reset } =
    useForm<IOrganizationFieldValues>({
      mode: 'onChange',
    });
  const { errors, isSubmitting, isValid, isDirty } = formState;
  // Local State
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IError[]>([]);
  const [isErrors, setIsErrors] = useState<boolean>(false);

  const clearSuccess = () => {
    setUpdateSuccess(false);
  };
  const clearIsErrors = () => {
    setIsErrors(false);
  };
  // Effects
  // - Loading
  useEffect(() => {
    if (updating) return;
    if (
      loadingSanityBaseLanguage ||
      loadingSanityOrganization ||
      loadingProfiles ||
      loadingCustomFields
    ) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [
    loadingCustomFields,
    loadingProfiles,
    loadingSanityBaseLanguage,
    loadingSanityOrganization,
    updating,
  ]);
  // - Success
  useEffect(() => {
    if (
      successSanityBaseLanguage &&
      successSanityOrganization &&
      successProfiles &&
      successCustomFields
    ) {
      setSuccess(true);
      return;
    }
    setSuccess(false);
  }, [
    successCustomFields,
    successProfiles,
    successSanityBaseLanguage,
    successSanityOrganization,
  ]);

  useEffect(() => {
    if (formErrors.length > 0) {
      setIsErrors(true);
    }
  }, [formErrors.length]);

  // Events

  const updateProfileForm = useCallback(
    async (data: IOrganizationFieldValues) => {
      try {
        if (organizationSlug && profile) {
          const {
            dateOfBirth,
            country,
            firstName,
            gender,
            jobTitle,
            lastName,
            townOrCity,
            privacyPolicy,
            pronouns,
            linkedInUrl,
            termsOfService,
            // react-hook-form values
            keepValues,
            // customFields values
            ...customFields
          } = data;
          const updateProfileResults = await updateProfile({
            reqProfiles,
            data: {
              dateOfBirth,
              country,
              firstName,
              gender,
              jobTitle,
              lastName,
              townOrCity,
              privacyPolicy,
              pronouns,
              linkedInUrl,
            },
            profileId,
          });

          if (!updateProfileResults.success) {
            if (updateProfileResults.errors) {
              setFormErrors([
                { message: errorAlertText },
                ...updateProfileResults.errors,
              ]);

              throw new Error('could not update profile');
            }
            setFormErrors([{ message: errorAlertText }]);

            throw new Error('could not update profile');
          }

          const updateCustomFieldsResults = await updateCustomFields({
            customFieldsList: profile.organizationFields as ICustomField[],
            data: customFields,
            profileId,
            organizationSlug,
            reqCustomFields,
          });

          if (!updateCustomFieldsResults.success) {
            if (updateCustomFieldsResults.errors) {
              setFormErrors([
                { message: errorAlertText },
                ...updateCustomFieldsResults.errors,
              ]);
              throw new Error('could not update profile');
            }
            setFormErrors([{ message: errorAlertText }]);
            throw new Error('could not update profile');
          }

          await Promise.all([
            reqProfiles({ url: `/profiles/${profileId}` }),
            reqCustomFields({ url: '/customFields' }),
          ]);

          setUpdateSuccess(true);
          navigate('/dashboard');
        }
      } catch (error) {
        if (error instanceof Error) {
          console.warn(error.message);
        }
        setLoading(false);
      } finally {
        setSuccess(true);
        setLoading(false);
        setUpdating(false);
      }
    },
    [
      errorAlertText,
      navigate,
      organizationSlug,
      profile,
      profileId,
      reqCustomFields,
      reqProfiles,
    ],
  );

  const onBeforeSubmit = () => {
    setFormErrors([]);
    setUpdating(true);
    setLoading(true);
  };

  const onSubmit: SubmitHandler<IOrganizationFieldValues> = useCallback(
    (data) => {
      const submitAsync = async () => {
        await updateProfileForm(data);
      };

      onBeforeSubmit();
      submitAsync();
      reset({ keepValues: true });
    },
    [reset, updateProfileForm],
  );

  const onError: SubmitErrorHandler<IProfileFieldValues> = (
    errors: FieldErrorsImpl<IProfileFieldValues>,
  ) => {
    setLoading(false);
    console.warn({ errors });
  };

  return {
    // React Hook Form
    control,
    handleSubmit,
    errors,
    isSubmitting,
    unregister,
    setValue,
    // Sanity Content
    sanityBaseLanguage,
    localeCode,
    // Profile
    profile,
    // Organization
    sanityOrganization: organization,
    sanityOrganizationProfileFields,
    sanityOrganizationPersonalDetails,
    organizationLogo,
    organizationName,
    additionalAgreements,
    organizationAgreements,
    // Effects
    loading,
    success,
    updateSuccess,
    clearSuccess,
    // Events
    onSubmit,
    onError,
    // Hook Errors
    errorsSanityBaseLanguage,
    errorsSanityOrganization,
    errorsProfiles,
    errorsCustomFields,
    // Errors/Validation
    formErrors,
    isErrors,
    clearIsErrors,
    isValid,
    isDirty,
  };
};

export default useCompleteProfileForm;

import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  EProgramVariation,
  IMembership,
  Program,
} from '@guider-global/shared-types';

import { IMembershipForm } from 'store/slices/formsSlice';

import ProgramManageGuideFields from 'components/ProgramManageGuideFields';
import ProgramManageGuideSkills from 'components/ProgramManageGuideSkills';
import { ProgramManageGuideTitle } from 'components/ProgramManageGuideTitle';
import { ProgramManageGuideDescription } from 'components/ProgramManageGuideDescription';
import { PublishProfile } from './components';

import {
  BaseReactHookFormComponentProps,
  ProgramManageGuidePageRole,
} from 'pages/Programs/ProgramManageGuidePage';
import { Asterisk, FormAutocomplete } from 'components';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

export interface ProgramManageGuideFormProps
  extends BaseReactHookFormComponentProps {
  onSubmit: SubmitHandler<IMembershipForm>;
  program?: Program;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  guideMembership: IMembership;
  role: ProgramManageGuidePageRole;
  profilePublishHeading: string;
  profilePublishDescription: string;
  discardChangesButtonLabel: string;
  updateProfileButtonLabel: string;
  profilePendingHeading: string;
  profilePendingDescription: string;
  goalCategories: { label?: string | undefined; value: string }[];
  loading: boolean;
  isValid: boolean;
  isDirty: boolean;
}

const ProgramManageGuideForm: FC<ProgramManageGuideFormProps> = ({
  control,
  errors,
  onSubmit,
  program,
  handleSubmit,
  loading,
  guideMembership,
  profilePublishHeading,
  profilePublishDescription,
  discardChangesButtonLabel,
  updateProfileButtonLabel,
  profilePendingHeading,
  profilePendingDescription,
  isValid,
  isDirty,
  role,
  goalCategories,
}) => {
  const navigate = useNavigate();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  if (!program) {
    return <></>;
  }

  const goalsValidation = (value: unknown) => {
    const valueLength = (value as string[]).length;
    if (valueLength < 1) return false;
    return true;
  };

  const pageName = 'Programs_ProgramManageGuidePage';
  const isGroupProgram =
    guideMembership?.programVariationTypeSlug === EProgramVariation.Group;

  // Derivations
  const programRegistration = program.registration;
  const skillsConfig = programRegistration?.skills;
  const isSkillsEnabled =
    program.registration?.skills?.enable_skills_question ?? false;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isGroupProgram && (
        <PublishProfile
          control={control}
          errors={errors}
          guideMembership={guideMembership}
          profilePublishHeading={profilePublishHeading}
          profilePublishDescription={profilePublishDescription}
          profilePendingHeading={profilePendingHeading}
          profilePendingDescription={profilePendingDescription}
        />
      )}
      {isGroupProgram && (
        <>
          <ProgramManageGuideTitle
            programRegistration={programRegistration}
            pageName={pageName}
            control={control}
            errors={errors}
          />
          <ProgramManageGuideDescription
            programRegistration={programRegistration}
            pageName={pageName}
            control={control}
            errors={errors}
          />
        </>
      )}
      <ProgramManageGuideSkills
        control={control}
        errors={errors}
        skillsConfig={skillsConfig}
        role={role}
        isGroupProgram={isGroupProgram}
      />
      {goalCategories && !isGroupProgram && (
        <Stack direction={'column'} mt={6}>
          <Typography variant="h6">
            {skillsConfig?.enable_skills_question ? '2. ' : '1. '}
            {
              program.program_details?.program_type?.program_type_text
                .variations?.individual?.registration?.registration_guide
                ?.registration_guide_goals?.registration_guide_goals_title
            }
            <Asterisk />
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {
              program.program_details?.program_type?.program_type_text
                .variations?.individual?.registration?.registration_guide
                ?.registration_guide_goals?.registration_guide_goals_description
            }
          </Typography>
          <FormAutocomplete
            multiple
            defaultValue={[]}
            name={'goalCategorySlugs'}
            control={control}
            label={baseLanguage.globals.goals.goals ?? 'Goals'}
            errors={errors}
            options={goalCategories}
            rules={{
              required: true,
              validate: goalsValidation,
            }}
          />
        </Stack>
      )}
      <ProgramManageGuideFields
        control={control}
        errors={errors}
        role={role}
        program={program}
        isSkillsEnabled={isSkillsEnabled}
        isGroupProgram={isGroupProgram}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: { xs: 'column nowrap', md: 'row nowrap' },
          justifyContent: { xs: 'stretch', md: 'space-between' },
          alignItems: { xs: 'stretch', md: 'flex-end' },
          gap: 3,
          mt: { xs: 2, md: 6 },
          mb: 2,
        }}
      >
        <Button
          data-cy={`pages_${pageName}_programs-button`}
          variant="outlined"
          color="info"
          size="large"
          onClick={() => navigate(-1)}
        >
          {discardChangesButtonLabel}
        </Button>
        <LoadingButton
          data-cy={`pages_${pageName}_submit-button`}
          variant="contained"
          color="info"
          size="large"
          type="submit"
          disabled={!isValid || !isDirty}
          sx={{
            color: isValid ? 'white' : 'inherit',
          }}
          loading={loading}
        >
          {updateProfileButtonLabel}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ProgramManageGuideForm;

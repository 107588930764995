import { useContext, useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { Descendant } from 'slate';
import { FormRichText } from 'components';
import { RelationshipNotesModalContext } from 'modals';
import { NoteFormField } from '../..';

interface NoteContentProps {
  control: Control;
  initialValue: Descendant[] | undefined;
}

export function NoteContent({ control, initialValue }: NoteContentProps) {
  const { setIsDirtyNote } = useContext(RelationshipNotesModalContext);

  const {
    field: { value },
    formState,
  } = useController({ name: NoteFormField.Content, control });

  useEffect(() => {
    setIsDirtyNote(!!Object.keys(formState.dirtyFields).length);
  }, [formState.dirtyFields, setIsDirtyNote, value]);

  return (
    <FormRichText
      name={NoteFormField.Content}
      control={control}
      initialValue={initialValue}
      required
      sx={{
        wordBreak: 'break-word',
        minHeight: '100%',
      }}
    />
  );
}

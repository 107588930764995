// external
import LocationOn from '@mui/icons-material/LocationOn';
import VideoCameraFront from '@mui/icons-material/VideoCameraFront';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { add } from 'date-fns';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';

// components
import {
  FormDatePicker,
  FormDropdown,
  FormTextField,
  ISessionInputs,
} from 'components';

// hooks
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import { useUserIntegrations, useMobileMediaQuery } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';

//types
import { TimeAdornmentStart } from '../components';
import { useDateTimeSlots } from '../hooks';

interface ISessionsCreateFormProps {
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  isLoadingSessions: boolean;
  discardAction: () => void;
  setValue: UseFormSetValue<ISessionInputs>;
  defaultTitle?: string;
  hideButtons?: boolean;
}

export const SessionsCreateForm: React.FC<ISessionsCreateFormProps> = ({
  handleSubmit,
  control,
  errors,
  isValid,
  isLoadingSessions,
  discardAction,
  setValue,
  defaultTitle,
  hideButtons,
}) => {
  const [date, startTime, location, videoConferencing] = useWatch({
    control,
    name: ['date', 'startTime', 'location', 'videoConferencing'],
  });

  //style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  //hooks
  const navigate = useNavigate();
  const { relationshipId } = useParams();

  //base language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  //organization
  const organizationSlug = getSubDomain();
  const { getOrganization } = useSanityOrganization({
    organizationSlug,
    getSilently: false,
  });
  const { white_label: whiteLabel } = getOrganization();
  const { integrations: enabledIntegrations } = whiteLabel;
  const {
    video_conferencing: videoIntegrations,
    calendar: calendarIntegrations,
  } = enabledIntegrations;

  //userIntegrations
  const { userIntegrations } = useUserIntegrations({
    waitForAuthentication: true,
  });

  const hasCalendarIntegrations =
    Object.values(calendarIntegrations).filter((value) => value === true)
      .length !== 0;

  const hasVideoIntegrations =
    Object.entries(videoIntegrations).filter(
      ([key, value]) => key !== 'guider_video' && value === true,
    ).length !== 0;

  const hasMSTeamsIntegrated = userIntegrations().find(
    (integration) => integration.integrationName === 'active-directory-teams',
  );

  //date time slots
  const now = new Date();
  const currentDate = date ?? now;
  const currentStartTime = startTime ?? '';
  const {
    isLoadingSlots,
    startTimeOptions,
    endTimeOptions,
    hasUnavailableSlots,
  } = useDateTimeSlots({
    now,
    currentDate,
    currentStartTime,
  });

  //handlers
  const handleIntegrationsModalOpen = () => {
    navigate(`/relationships/${relationshipId}/sessions/integrate`);
  };

  useEffect(() => {
    setValue('endTime', '');
  }, [startTime, setValue]);

  return (
    <form onSubmit={handleSubmit}>
      <FormTextField
        name="title"
        control={control}
        label={
          baseLanguage.relationships.sessions.common.session_title_input_label
        }
        errors={errors}
        required
        defaultValue={defaultTitle}
      />
      <FormDatePicker
        name="date"
        control={control}
        label={baseLanguage.relationships.sessions.common.date_input_label}
        errors={errors}
        required
        minDate={now}
        defaultValue={now}
        maxDate={hasUnavailableSlots ? add(now, { days: 62 }) : undefined}
      />
      {hasCalendarIntegrations && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.75rem',
            textTransform: 'none',
            ml: 0.25,
            mt: 0.5,
          }}
        >
          <Link
            onClick={handleIntegrationsModalOpen}
            sx={{
              textDecoration: 'none',
              ':hover': { cursor: 'pointer' },
              color: theme.palette.info.main,
            }}
          >
            {
              baseLanguage.relationships.sessions.common
                .manage_calendar_integrations_button_label
            }
          </Link>
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <FormDropdown
          name="startTime"
          data-cy="forms_session_FormTimeField_startTime"
          control={control}
          label={
            baseLanguage.relationships.sessions.common.start_time_input_label
          }
          errors={errors}
          required
          options={startTimeOptions}
          noOptionsLabel={
            baseLanguage.relationships.sessions.common.no_slots_available_label
          }
          startAdornment={<TimeAdornmentStart isLoading={isLoadingSlots} />}
          disabled={isLoadingSlots}
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 1.25, border: 'none' }}
          />
        )}
        <FormDropdown
          name="endTime"
          control={control}
          label={
            baseLanguage.relationships.sessions.common.end_time_input_label
          }
          errors={errors}
          required
          options={endTimeOptions}
          startAdornment={<TimeAdornmentStart isLoading={isLoadingSlots} />}
          disabled={isLoadingSlots || !startTime}
          data-cy="forms_session_FormTimeField_endTime"
        />
      </Box>
      <FormDropdown
        name="videoConferencing"
        control={control}
        label={
          baseLanguage.relationships.sessions.common
            .video_conferencing_select_label
        }
        errors={errors}
        options={[
          ...(hasMSTeamsIntegrated
            ? [
                {
                  label:
                    baseLanguage.relationships.sessions.common
                      .ms_teams_select_option,
                  value: 'ms-teams',
                },
              ]
            : []),
          {
            label:
              baseLanguage.relationships.sessions.common
                .guider_video_select_option,
            value: 'guider',
          },
          {
            label:
              baseLanguage.relationships.sessions.common.no_video_select_option,
            value: '',
          },
        ]}
        required={location === ''}
        defaultValue=""
        startAdornment={
          <InputAdornment position="start">
            <VideoCameraFront />
          </InputAdornment>
        }
      />
      {hasVideoIntegrations && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.75rem',
            textTransform: 'none',
            ml: 0.25,
            mt: 0.5,
          }}
        >
          <Link
            onClick={handleIntegrationsModalOpen}
            sx={{
              textDecoration: 'none',
              ':hover': { cursor: 'pointer' },
              color: theme.palette.info.main,
            }}
          >
            {
              baseLanguage.relationships.sessions.common
                .manage_conferencing_integrations_button_label
            }
          </Link>
        </Typography>
      )}
      <FormTextField
        name="location"
        control={control}
        label={baseLanguage.relationships.sessions.common.location_input_label}
        errors={errors}
        defaultValue=""
        required={
          !videoConferencing && {
            value: true,
            message: `You must provide either ${baseLanguage.relationships.sessions.common.location_input_label} or ${baseLanguage.relationships.sessions.common.video_conferencing_select_label} for the session`,
          }
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOn />
            </InputAdornment>
          ),
        }}
      />
      {!hideButtons && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <Button
            data-cy="forms-Sessions-SessionsCreateForm_discard-button"
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            onClick={discardAction}
          >
            {baseLanguage.globals?.common?.cancel_button_label ?? 'Cancel'}
          </Button>
          <LoadingButton
            data-cy="forms-Sessions-SessionsCreateForm_submit-button"
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid}
            sx={{
              color: isValid ? 'white' : 'inherit',
              mb: isMobile ? 2 : 0,
            }}
            fullWidth={isMobile}
            loading={isLoadingSessions}
          >
            {
              baseLanguage.relationships.sessions.new_session_modal
                .send_calendar_invite_button_label
            }
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};

export default SessionsCreateForm;

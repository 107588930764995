import React from 'react';
import { FieldErrorsImpl, Control, UseFormSetValue } from 'react-hook-form';

import { ICustomField, SanityProfileField } from '@guider-global/shared-types';

import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { ProfileFormsCustomFields } from 'forms/ProfileForms/components/ProfileFormsCustomFields';

export interface IProfileFormCustomFields {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
}

interface CustomField extends SanityProfileField {
  defaultValue?: string | string[] | boolean;
}

export const ProfileFormCustomFields: React.FunctionComponent<
  IProfileFormCustomFields
> = ({
  control,
  errors,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  const { profile, sanityOrganizationProfileFields } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
  });

  if (!sanityOrganizationProfileFields) return null;
  const profileValues = (profile?.organizationFields as ICustomField[]) ?? [];
  const customFields: CustomField[] = sanityOrganizationProfileFields.map(
    (customField) => {
      const defaultValues = profileValues.filter(
        (value) => value.fieldSlug === customField.id.current,
      );

      const defaultValue =
        defaultValues.length > 0 ? defaultValues[0].value : undefined;

      return {
        ...customField,
        defaultValue,
      };
    },
  );

  return (
    <ProfileFormsCustomFields
      control={control}
      errors={errors}
      customFields={customFields}
      setValue={setValue}
    />
  );
};

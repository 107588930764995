import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Grid, Skeleton } from '@mui/material';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useMixpanelEvents } from 'hooks';
import React, { useCallback } from 'react';
import {
  ProfileCountryDropdown,
  ProfileDateOfBirthField,
  ProfileFirstNameField,
  ProfileFormCustomFields,
  ProfileFormGuiderLegalCheckbox,
  ProfileFormLinkedInUrlField,
  ProfileGenderDropdown,
  ProfileJobTitleField,
  ProfileLastNameField,
  ProfilePronounsField,
  ProfileTownOrCityField,
} from './components';
import ProfileFormCustomLegalCheckboxes from './components/ProfileFormCustomLegalCheckboxes';
import { IOrganizationProfileFormProps } from './types';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
export * from './types';

export const OrganizationProfileForm: React.FunctionComponent<
  IOrganizationProfileFormProps
> = ({
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
  getUsersSilently = false,
  getOrganizationsSilently = false,
  getCustomFieldsSilently = false,
}) => {
  const { trackScreenTime } = useMixpanelEvents();
  const {
    isReady,
    hasErrors,
    formErrors,
    profileErrors,
    customFieldErrors,
    control,
    errors,
    setValue,
    unregister,
    handleSubmit,
    onSubmit,
    onError,
    isSubmitting,
  } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
    getUsersSilently,
    getOrganizationsSilently,
    getCustomFieldsSilently,
  });

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const continueButtonLabel =
    baseLanguage.globals?.common?.continue_button_label ?? 'Continue';

  // TODO Refactor to another component or App alerts reducer?
  // API Errors Alert component
  const ErrorsAlert = useCallback(
    () =>
      hasErrors ? (
        <Box mt={2}>
          <Alert severity="error">
            {[...formErrors, ...profileErrors, ...customFieldErrors].map(
              (error) => error.message,
            )}
          </Alert>
        </Box>
      ) : null,
    [customFieldErrors, formErrors, hasErrors, profileErrors],
  );

  const renderSkeleton = () => {
    return (
      <>
        <Skeleton sx={{ height: '56px', mt: 2.5, transform: 'none' }} />
        <Skeleton sx={{ height: '56px', mt: 2.5, transform: 'none' }} />
        <Skeleton sx={{ height: '125px', mt: 2.5, transform: 'none' }} />
        <Skeleton sx={{ height: '56px', mt: 2.5, transform: 'none' }} />
        <Skeleton sx={{ height: '56px', mt: 2.5, transform: 'none' }} />
        <Skeleton sx={{ height: '56px', mt: 2.5, transform: 'none' }} />
        <Skeleton sx={{ height: '125px', mt: 2.5, transform: 'none' }} />
      </>
    );
  };

  const renderFormFields = () => {
    return (
      <>
        <ProfileFirstNameField
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <ProfileLastNameField
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <ProfilePronounsField
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <ProfileDateOfBirthField
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <ProfileGenderDropdown
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <ProfileJobTitleField
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <ProfileTownOrCityField
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <ProfileCountryDropdown
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        <ProfileFormLinkedInUrlField
          control={control}
          errors={errors}
          unregister={unregister}
          setValue={setValue}
        />
        {/* Custom Profile fields */}

        <ProfileFormCustomFields
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <ProfileFormGuiderLegalCheckbox
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <ProfileFormCustomLegalCheckboxes control={control} errors={errors} />
      </>
    );
  };

  return (
    <>
      <ErrorsAlert />
      <Grid container>
        <Grid item xs={12}>
          <Box width="100%" height="100%" display="flex">
            <Box
              sx={{ width: '100%' }}
              component="form"
              onSubmit={async (...args) => {
                await handleSubmit(onSubmit, onError)(...args);
                trackScreenTime('Registration - Profile completed', {
                  'Profile completed': getISOStringWithoutMilliseconds(),
                });
              }}
            >
              <>
                {!isReady && !isSubmitting
                  ? renderSkeleton()
                  : renderFormFields()}
                <LoadingButton
                  fullWidth
                  disableElevation
                  variant="contained"
                  disabled={!isReady}
                  loading={!isReady}
                  size="large"
                  sx={{ mt: 2 }}
                  type="submit"
                  color="info"
                >
                  {continueButtonLabel}
                </LoadingButton>
              </>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

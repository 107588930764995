import { ICustomField } from '@guider-global/shared-types';

import {
  UpdateProfileResults,
  UpdateCustomFieldsProps,
  UpdateCustomFieldsResults,
  UpdateProfileProps,
} from './types';

export const updateProfile = async ({
  reqProfiles,
  data,
  profileId,
}: UpdateProfileProps): Promise<UpdateProfileResults> => {
  const {
    dateOfBirth,
    country,
    firstName,
    gender,
    jobTitle,
    lastName,
    townOrCity,
    privacyPolicy,
    pronouns,
    linkedInUrl,
  } = data;
  const displayname =
    firstName || lastName ? `${data.firstName} ${data.lastName}` : undefined;

  const updateProfileFieldsData = {
    dateOfBirth,
    country,
    firstName,
    gender,
    jobTitle,
    lastName,
    townOrCity,
    pronouns,
    privacyPolicy: privacyPolicy as boolean,
    displayName: displayname,
    linkedInUrl: linkedInUrl && encodeURI(linkedInUrl),
  };
  const isNoData =
    Object.values(updateProfileFieldsData).filter((val) => val !== undefined)
      .length === 0;

  if (isNoData) {
    return {
      success: true,
    };
  }
  const profilesResult = await reqProfiles({
    method: 'PATCH',
    url: `/profiles/${profileId}`,
    data: updateProfileFieldsData,
  });

  const profilesResultData = profilesResult?.data;

  if (profilesResult.status !== 'success' || !profilesResultData) {
    if (profilesResult.errors) {
      const errors = profilesResult.errors.map((err) => {
        return {
          message: err.message ?? 'Unknown Error',
          code: err.code ?? 'Unknown Error Code',
        };
      });
      return {
        success: false,
        errors,
      };
    } else {
      return {
        success: false,
        errors: [{ message: 'Unknown Error', code: 'Unknown Error Code' }],
      };
    }
  }
  const profileResultData = profilesResultData[0];

  return {
    success: true,
    profile: profileResultData,
  };
};

export const updateCustomFields = async ({
  customFieldsList,
  data,
  profileId,
  organizationSlug,
  reqCustomFields,
}: UpdateCustomFieldsProps): Promise<UpdateCustomFieldsResults> => {
  const formCustomFieldKeys = Object.keys(data);

  const existingCustomFieldsNames = customFieldsList.flatMap((field) =>
    formCustomFieldKeys.filter((name) => name === field.fieldSlug),
  );

  const newCustomFieldKeys = formCustomFieldKeys.filter(
    (key) => !existingCustomFieldsNames.includes(key),
  );

  const existingCustomFields = existingCustomFieldsNames.reduce(
    (acc, name) => {
      const customFieldEntries = customFieldsList.find((field) => {
        return field.fieldSlug === name;
      });
      const customFieldEntry = customFieldEntries;

      return [...acc, { ...customFieldEntry, fieldName: name }];
    },
    [] as Array<Partial<ICustomField> & { fieldName: string }>,
  );

  const newCustomFields = newCustomFieldKeys
    .map((name) => {
      if (typeof data[name] === 'boolean') {
        return {
          fieldSlug: name,
          organizationSlug,
          fieldType: 'check',
          value: data[name],
          profileId,
        };
      }
      if (typeof data[name] === 'string') {
        return {
          fieldSlug: name,
          organizationSlug,
          fieldType: 'select',
          value: data[name],
          profileId,
        };
      }
      if (data[name]) {
        return {
          fieldSlug: name,
          organizationSlug,
          fieldType: 'multi-select',
          value: data[name],
          profileId,
        };
      }
      return undefined;
    })
    .filter((value) => value !== undefined);

  // Create new custom fields if required
  if (newCustomFields.length > 0) {
    const customFieldsPostResult = await reqCustomFields({
      method: 'POST',
      url: `/customfields`,
      data: [...newCustomFields] as ICustomField[],
    });
    if (customFieldsPostResult.status !== 'success') {
      const errors = customFieldsPostResult.errors
        ? customFieldsPostResult.errors.map((err) => {
            return { message: err.message ?? 'Unknown Error' };
          })
        : [{ message: 'Unknown Error' }];

      return {
        success: false,
        errors,
      };
    }
  }
  // Create update custom fields
  const customFieldsPatchPromises = existingCustomFields.map((field) => {
    return reqCustomFields({
      method: 'PATCH',
      url: `/customfields/${field.id}`,
      data: {
        fieldType: field.fieldType,
        value: data[field.fieldName],
      } as ICustomField,
    });
  });

  const customFieldsPatchResults = await Promise.all(customFieldsPatchPromises);

  if (customFieldsPatchResults.find((result) => result?.status !== 'success')) {
    const errors = customFieldsPatchResults.flatMap((result) => {
      return result && result.errors
        ? result.errors.map((err) => {
            return {
              message: err.message ?? 'Unknown Error',
              code: err.code ?? 'Unknown Error Code',
            };
          })
        : [
            {
              message: 'Unknown Error',
              code: 'Unknown Error Code',
            },
          ];
    });

    return {
      success: false,
      errors,
    };
  }

  const customFields = customFieldsPatchResults
    .flatMap((result) => {
      if (!result) return undefined;
      if (!result.data) return undefined;
      return result.data;
    })
    .filter((result) => result !== undefined) as ICustomField[];

  return {
    success: true,
    customFields,
  };
};

import { Box } from '@mui/material';
import { FormCheckbox } from 'components';
import { SettingsAvailabilitiesFields } from 'forms/AvailabilityForm/types';
import { Control, FieldErrorsImpl } from 'react-hook-form';

interface AvailabilityStatusProps {
  label: string;
  name: keyof SettingsAvailabilitiesFields;
  control: Control<SettingsAvailabilitiesFields>;
  errors: FieldErrorsImpl<SettingsAvailabilitiesFields>;
}

export function AvailabilityStatus(props: AvailabilityStatusProps) {
  return (
    <Box
      sx={{
        width: 'max(125px, 15%)',
        mr: 2.5,
      }}
    >
      <FormCheckbox {...props} />
    </Box>
  );
}

import React from 'react';
import { FieldErrorsImpl, Control } from 'react-hook-form';

import { ICustomField, SanityProfileField } from '@guider-global/shared-types';

import { IOrganizationFieldValues } from 'forms';
import { Skeleton } from '@mui/material';
import { ProfileFormsCustomFields } from 'forms/ProfileForms/components/ProfileFormsCustomFields';

export interface CompleteProfileFormCustomFieldsProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  profileOrganizationFields: ICustomField[];
  sanityOrganizationProfileFields: SanityProfileField[];
  loading?: boolean;
}

interface CustomField extends SanityProfileField {
  defaultValue?: string | string[] | boolean;
}

export const CompleteProfileFormCustomFields: React.FunctionComponent<
  CompleteProfileFormCustomFieldsProps
> = ({
  control,
  errors,
  profileOrganizationFields,
  sanityOrganizationProfileFields,
  loading,
}) => {
  if (!sanityOrganizationProfileFields) return null;
  if (loading) {
    return <Skeleton variant="rectangular" sx={{ height: '56px', mt: 2.5 }} />;
  }

  const customFields: CustomField[] = sanityOrganizationProfileFields.map(
    (customProfileField) => {
      const formDefaultValues = profileOrganizationFields.filter(
        (customField) =>
          customField.fieldSlug === customProfileField.id.current,
      );

      const customProfileFieldDefaultValue =
        formDefaultValues.length > 0 ? formDefaultValues[0].value : undefined;

      return {
        ...customProfileField,
        defaultValue: customProfileFieldDefaultValue,
      };
    },
  );
  const filterDefinedFields = customFields.filter(
    (customField) => !customField.defaultValue,
  );

  return (
    <ProfileFormsCustomFields
      control={control}
      errors={errors}
      customFields={filterDefinedFields}
    />
  );
};

import { Box } from '@mui/material';
import { Control, FieldErrorsImpl, UseFormUnregister } from 'react-hook-form';
import { FC } from 'react';

import {
  ICustomField,
  IProfile,
  SanityBaseLanguageProfileFields,
  SanityPersonalDetails,
  SanityProfileField,
} from '@guider-global/shared-types';

import { IOrganizationFieldValues } from 'forms/ProfileForms/ProfileForm';
import {
  EditProfileFirstNameField,
  EditProfileLastNameField,
  EditProfilePronounsField,
  EditProfileDateOfBirthField,
  EditProfileGenderDropdown,
  EditProfileJobTitleField,
  EditProfileTownOrCityField,
  EditProfileCountryDropdown,
  EditProfileFormCustomFields,
  EditProfileFormLinkedInUrlField,
} from 'forms/ProfileForms/ProfileEditForm/components';

import { sanityFieldToOptionsArray } from 'utils';
import { useValidationErrorBlank } from 'hooks';

export interface ProfileEditFormProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister: UseFormUnregister<IOrganizationFieldValues>;
  baseLanguageProfileFields: SanityBaseLanguageProfileFields;
  profile: IProfile;
  sanityOrganizationProfileFields: SanityProfileField[];
  sanityOrganizationPersonalDetails: SanityPersonalDetails;
  loading?: boolean;
}

const ProfileEditForm: FC<ProfileEditFormProps> = ({
  control,
  errors,
  unregister,
  baseLanguageProfileFields,
  profile,
  sanityOrganizationProfileFields,
  sanityOrganizationPersonalDetails,
  loading,
}) => {
  const {
    linkedin_url: linkedInUrl,
    gender,
    country,
  } = baseLanguageProfileFields;

  const { getValidationErrorBlank } = useValidationErrorBlank();

  return (
    <>
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr',
          columnGap: 3,
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr',
          },
        })}
      >
        <EditProfileFirstNameField
          control={control}
          errors={errors}
          label={baseLanguageProfileFields.first_name_input_label}
          value={profile.firstName ?? ''}
          errorMessage={getValidationErrorBlank(
            baseLanguageProfileFields.first_name_input_label,
          )}
          loading={loading}
        />
        <EditProfileLastNameField
          control={control}
          errors={errors}
          label={baseLanguageProfileFields.last_name_input_label}
          value={profile.lastName ?? ''}
          errorMessage={getValidationErrorBlank(
            baseLanguageProfileFields.last_name_input_label,
          )}
          loading={loading}
        />
      </Box>

      <EditProfilePronounsField
        control={control}
        errors={errors}
        unregister={unregister}
        label={baseLanguageProfileFields.pronouns_input_label}
        value={profile.pronouns ?? ''}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.pronouns_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.pronouns.enabled}
        required={sanityOrganizationPersonalDetails.pronouns.required}
      />
      <EditProfileDateOfBirthField
        control={control}
        errors={errors}
        unregister={unregister}
        label={baseLanguageProfileFields.date_of_birth_input_label}
        value={profile.dateOfBirth ?? ''}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.date_of_birth_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.date_of_birth.enabled}
        required={sanityOrganizationPersonalDetails.date_of_birth.required}
      />
      <EditProfileGenderDropdown
        control={control}
        errors={errors}
        unregister={unregister}
        label={gender.gender_input_label}
        value={profile.gender ?? ''}
        errorMessage={gender.gender_validation_error_not_set}
        options={sanityFieldToOptionsArray(gender.options)}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.gender.enabled}
        required={sanityOrganizationPersonalDetails.gender.required}
      />
      <EditProfileJobTitleField
        control={control}
        errors={errors}
        unregister={unregister}
        label={
          sanityOrganizationPersonalDetails.job_title?.custom_label ??
          baseLanguageProfileFields.job_title_input_label
        }
        value={profile.jobTitle ?? ''}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.job_title_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.job_title.enabled}
        required={sanityOrganizationPersonalDetails.job_title.required}
      />
      <EditProfileTownOrCityField
        control={control}
        errors={errors}
        unregister={unregister}
        label={baseLanguageProfileFields.town_or_city_input_label}
        value={profile.townOrCity ?? ''}
        errorMessage={getValidationErrorBlank(
          baseLanguageProfileFields.town_or_city_input_label,
        )}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.town_or_city.enabled}
        required={sanityOrganizationPersonalDetails.town_or_city.required}
      />
      <EditProfileCountryDropdown
        control={control}
        errors={errors}
        unregister={unregister}
        label={
          sanityOrganizationPersonalDetails.country?.custom_label ??
          country.country_input_label
        }
        value={profile.country ?? ''}
        errorMessage={country.country_validation_error_not_set}
        loading={loading}
        enabled={sanityOrganizationPersonalDetails.country.enabled}
        required={sanityOrganizationPersonalDetails.country.required}
      />
      <EditProfileFormLinkedInUrlField
        control={control}
        errors={errors}
        unregister={unregister}
        label={linkedInUrl.input_label}
        value={profile.linkedInUrl ?? ''}
        validateErrorMessage={linkedInUrl.validation_error_invalid}
        requiredErrorMessage={getValidationErrorBlank(linkedInUrl.input_label)}
        loading={loading}
        required={sanityOrganizationPersonalDetails.linkedin_url.required}
        enabled={sanityOrganizationPersonalDetails.linkedin_url.enabled}
      />
      {/* Custom Profile fields */}
      <EditProfileFormCustomFields
        control={control}
        errors={errors}
        organizationFields={profile.organizationFields as ICustomField[]}
        sanityOrganizationProfileFields={sanityOrganizationProfileFields}
        loading={loading}
      />
    </>
  );
};

export default ProfileEditForm;

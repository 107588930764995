import { FunctionComponent } from 'react';
import { FieldErrorsImpl, Control, UseFormUnregister } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import { IOrganizationFieldValues } from 'forms';
import { FormTextFieldTyped } from 'components';

export interface EditProfilePronounsFieldProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  label: string;
  value: string;
  errorMessage: string;
  loading?: boolean;
  required?: boolean;
  enabled?: boolean;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
}

export const EditProfilePronounsField: FunctionComponent<
  EditProfilePronounsFieldProps
> = ({
  control,
  errors,
  label,
  value,
  errorMessage,
  loading,
  required,
  enabled,
  unregister,
}) => {
  if (loading) {
    return <Skeleton variant="rectangular" sx={{ height: '56px', mt: 2.5 }} />;
  }

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="pronouns"
      data-cy="forms_ProfileEditForm_pronouns-field"
      control={control}
      errors={errors}
      label={label}
      rules={{
        required,
      }}
      defaultValue={value}
      requiredErrorMessage={errorMessage}
      enabled={enabled}
      unregister={unregister}
    />
  );
};

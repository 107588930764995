import { useContext, useEffect } from 'react';
import { Control, FieldErrors, useController } from 'react-hook-form';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextField, INoteInputs } from 'components';
import { RelationshipNotesModalContext } from 'modals';
import { NoteFormField } from '../..';

interface NoteTitleProps {
  control: Control;
  initialValue: string | undefined;
  errors: FieldErrors<INoteInputs>;
}

export function NoteTitle({ control, initialValue, errors }: NoteTitleProps) {
  const { setIsDirtyNote } = useContext(RelationshipNotesModalContext);
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const {
    field: { value },
    formState,
  } = useController({ name: NoteFormField.Title, control });

  useEffect(() => {
    setIsDirtyNote(!!Object.keys(formState.dirtyFields).length);
  }, [formState.dirtyFields, setIsDirtyNote, value]);

  return (
    <FormTextField
      name={NoteFormField.Title}
      control={control}
      label={
        baseLanguage.relationships.notes.notes_modal
          .notes_modal_create_edit_note.note_title_input_label
      }
      errors={errors}
      required
      defaultValue={initialValue}
      data-cy="forms_notes_notesCreateForm_title"
    />
  );
}

import { Liquid } from 'liquidjs';

export function interpolate(template: string, context: Record<string, string>) {
  try {
    const liquid = new Liquid();
    return liquid.parseAndRenderSync(template, context);
  } catch (err) {
    console.error('Failed to interpolate string.', { template, context, err });
    return 'N/A';
  }
}

import {
  ICustomField,
  IError,
  IProfile,
  IUser,
  SanityBaseLanguageLegalNotices,
  SanityBaseLanguageOnboarding,
  SanityBaseLanguageProfileFields,
  SanityBaseLanguageSettings,
  SanityOrganizationBasicInfo,
  SanityOrganizationWhiteLabel,
  SanityPersonalDetails,
} from '@guider-global/shared-types';
import {
  useProfiles,
  useUsers,
  useOrganizations,
  useCustomFields,
  useLocalization,
  useSettings,
} from 'hooks';
import { useEffect, useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  IOrganizationFieldValues,
  IProfileFieldValues,
} from 'forms/ProfileForms/ProfileForm';
import { IUseProfileFormProps, IUseProfileFormResult } from './types';
import { useNavigate } from 'react-router-dom';
import { updateProfile, updateCustomFields } from '../../../utils';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
} from '@guider-global/sanity-hooks';
import moment from 'moment-timezone';

export function useProfileForm({
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getUsersSilently = false,
  getOrganizationsSilently = false,
  getProfilesSilently = false,
  getCustomFieldsSilently = false,
}: IUseProfileFormProps): IUseProfileFormResult {
  const navigate = useNavigate();
  // Get current domain
  const subdomain = getSubDomain();

  // Sanity base language hook
  const { isSuccessSanityBaseLanguage, getBaseLanguage } =
    useSanityBaseLanguage({
      getSilently: getSanityBaseLanguageSilently,
    });

  // Sanity base language derivatives
  const sanityBaseLanguageProfileFields: SanityBaseLanguageProfileFields =
    getBaseLanguage().profile_fields;
  const sanityBaseLanguageLegalNotices: SanityBaseLanguageLegalNotices =
    getBaseLanguage().legal_notices;
  const sanityBaseLanguageSettings: SanityBaseLanguageSettings =
    getBaseLanguage().settings;

  // Sanity organization hook
  const { isSuccessSanityOrganization, getOrganization } =
    useSanityOrganization({
      organizationSlug: subdomain,
      getSilently: getSanityOrganizationSilently,
    });

  // Sanity organization derivatives
  const sanityOrganizationPersonalDetailsFields: SanityPersonalDetails =
    getOrganization().personal_details;
  const sanityOrganizationProfileFields =
    getOrganization().profile_fields ?? [];
  const localeCode: string = getBaseLanguage().locale_code.current;
  const sanityOrganizationWhiteLabel: SanityOrganizationWhiteLabel =
    getOrganization().white_label;
  const sanityOrganizationOnboardingFields: SanityBaseLanguageOnboarding =
    getBaseLanguage().onboarding;
  const sanityOrganizationBasicInfo: SanityOrganizationBasicInfo =
    getOrganization().basic_info;

  // Users hook
  const { users, isSuccessUsers, isErrorUsers, getErrorsUsers } = useUsers({
    getSilently: getUsersSilently,
    getSilentlyUrl: `/users`,
  });

  // Users derivatives
  const user: IUser = users()[0];

  // Organizations hook
  const { organizations, hasResultsOrganizations, isSuccessOrganizations } =
    useOrganizations({
      getSilently: getOrganizationsSilently,
    });

  // Organization derivatives
  const filteredOrganization = organizations().filter(
    (org) => org.slug === subdomain,
  )[0];
  const organizationSlug: string = filteredOrganization
    ? filteredOrganization.slug
    : subdomain;

  // Profiles hook
  const {
    getProfiles,
    reqProfiles,
    isSuccessProfiles,
    isErrorProfiles,
    getErrorsProfiles,
  } = useProfiles({
    getSilently: getProfilesSilently,
    forceRefresh: getProfilesSilently,
    getSilentlyUrl: '/profiles',
    queryParams: {
      integrationsEnabled: true,
    },
  });

  // Profile derivatives
  const [profile] = getProfiles();
  const profileId = profile?.id ?? '';

  const { localeCode: selectedLocaleCode } = useLocalization(organizationSlug);
  const { createSettings } = useSettings({});

  // CustomFields Hook
  const {
    reqCustomFields,
    isSuccessCustomFields,
    getErrorsCustomFields,
    isErrorCustomFields,
  } = useCustomFields({
    getSilently: getCustomFieldsSilently,
  });

  // Form hook
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    unregister,
    setValue,
  } = useForm<IOrganizationFieldValues>({ mode: 'onChange' });

  // Form lifecycle
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [isProfilePostSuccess, setIsProfilePostSuccess] =
    useState<boolean>(false);
  const [isCustomFieldPostSuccess, setIsCustomFieldPostSuccess] =
    useState<boolean>(false);
  const [
    isProfileCustomFieldsPatchSuccess,
    setIsProfileCustomFieldsPatchSuccess,
  ] = useState<boolean>(false);

  const [isCustomFieldPatchSuccess, setIsCustomFieldPatchSuccess] =
    useState<boolean>(false);
  const [isProfilePatchSuccess, setIsProfilePatchSuccess] =
    useState<boolean>(false);

  // API Errors
  const profileErrors: IError[] = getErrorsProfiles();
  const customFieldErrors: IError[] = getErrorsCustomFields();

  // Form errors
  const [formErrors, setFormErrors] = useState<IError[]>([]);

  // Error State
  const hasErrors =
    formErrors.length > 0 ||
    profileErrors.length > 0 ||
    customFieldErrors.length > 0;

  // Before Submit
  async function onBeforeSubmit(): Promise<void> {
    setFormErrors([]);
    setIsFormLoading(true);
  }

  // Create Profile
  async function createProfile(data: IOrganizationFieldValues): Promise<void> {
    try {
      if (organizationSlug) {
        const {
          dateOfBirth,
          country,
          firstName,
          gender,
          jobTitle,
          lastName,
          townOrCity,
          privacyPolicy,
          termsOfService,
          pronouns,
          linkedInUrl,
          // react-hook-form values
          keepValues,
          // customFields values
          ...customFields
        } = data;

        const profilesResult = await reqProfiles({
          method: 'POST',
          url: `/profiles`,
          data: {
            dateOfBirth,
            country,
            firstName,
            gender,
            jobTitle,
            lastName,
            townOrCity,
            pronouns,
            privacyPolicy: privacyPolicy ?? false,
            termsOfService: termsOfService ?? false,
            displayName: data.firstName + ' ' + data.lastName,
            organizationSlug,
            isOnboarded: false,
            linkedInUrl,
          },
        });

        const profileResultData: IProfile[] =
          profilesResult?.data as IProfile[];

        if (
          profilesResult.status !== 'success' ||
          profileResultData.length <= 0
        ) {
          setFormErrors(
            profilesResult.errors
              ? profilesResult.errors.map((err) => {
                  return { message: err.message ?? 'Unknown Error' };
                })
              : [{ message: 'Unknown Error' }],
          );
          throw new Error('Could not create Profile');
        }

        setIsProfilePostSuccess(true);

        const newProfileId = profileResultData[0].id;

        const customFieldsPostData = Object.keys(customFields)
          .map((name) => {
            if (typeof data[name] === 'boolean') {
              return {
                fieldSlug: name,
                organizationSlug,
                fieldType: 'check',
                value: data[name],
                profileId: newProfileId,
              };
            }
            if (typeof data[name] === 'string') {
              return {
                fieldSlug: name,
                organizationSlug,
                fieldType: 'select',
                value: data[name],
                profileId: newProfileId,
              };
            }
            if (data[name]) {
              return {
                fieldSlug: name,
                organizationSlug,
                fieldType: 'multi-select',
                value: data[name],
                profileId: newProfileId,
              };
            }
            return undefined;
          })
          .filter((value) => value !== undefined);

        const customFieldsResult = await reqCustomFields({
          method: 'POST',
          url: `/customfields`,
          data: [...customFieldsPostData] as ICustomField[],
        });

        if (customFieldsResult.status !== 'success') {
          setFormErrors(
            customFieldsResult.errors
              ? customFieldsResult.errors.map((err) => {
                  return { message: err.message ?? 'Unknown Error' };
                })
              : [{ message: 'Unknown Error' }],
          );
          throw new Error('Could not create Custom Organization Fields');
        }

        const timezone = moment.tz.guess(true);

        setIsCustomFieldPostSuccess(true);
        await Promise.all([
          updateProfileCustomFields(newProfileId),
          createSettings({
            profile: newProfileId,
            localeCode: selectedLocaleCode,
            timezone,
          }),
        ]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error.message);
        const { message } = error;
        setFormErrors([...formErrors, { message }]);
      }
    } finally {
      setIsFormLoading(false);
    }
  }

  // Update profile
  async function updateProfileForm(
    data: IOrganizationFieldValues,
  ): Promise<void> {
    try {
      if (organizationSlug && profile) {
        const {
          dateOfBirth,
          country,
          firstName,
          gender,
          jobTitle,
          lastName,
          townOrCity,
          privacyPolicy,
          pronouns,
          linkedInUrl,
          termsOfService,
          // react-hook-form values
          keepValues,
          // customFields values
          ...customFields
        } = data;

        const updateProfileResults = await updateProfile({
          reqProfiles,
          data: {
            dateOfBirth,
            country,
            firstName,
            gender,
            jobTitle,
            lastName,
            townOrCity,
            privacyPolicy,
            pronouns,
            linkedInUrl,
          },
          profileId,
        });

        if (!updateProfileResults.success) {
          if (updateProfileResults.errors) {
            setFormErrors(updateProfileResults.errors);
            throw new Error('Could not update Profile');
          }
        }

        setIsProfilePatchSuccess(true);

        const updateCustomFieldsResults = await updateCustomFields({
          customFieldsList: profile.organizationFields as ICustomField[],
          data: customFields,
          profileId,
          organizationSlug,
          reqCustomFields,
        });

        if (!updateCustomFieldsResults.success) {
          if (updateCustomFieldsResults.errors) {
            setFormErrors(updateCustomFieldsResults.errors);
            throw new Error('Could not update Profile');
          }
        }
        setIsCustomFieldPatchSuccess(true);

        await updateProfileCustomFields(profile.id);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error.message);
        const { message } = error;
        setFormErrors([...formErrors, { message }]);
      }
    } finally {
      setIsFormLoading(false);
    }
  }

  async function updateProfileCustomFields(profileIdToUpdate: string) {
    try {
      const customFields = await reqCustomFields({
        url: '/customfields',
        params: {
          profileId: profileIdToUpdate,
        },
      });
      const result = await reqProfiles({
        method: 'PATCH',
        url: `/profiles/${profileIdToUpdate}`,
        data: {
          organizationFields: customFields.data
            ? [...customFields.data.map((customField) => customField.id)]
            : [],
          isOnboarded: true,
        },
      });
      if (result.status === 'error') {
        const errors: IError[] = result.errors
          ? result.errors.map((err) => {
              return { message: err.message ?? 'Unknown Error' };
            })
          : [];
        setFormErrors(errors);
      } else {
        setIsProfileCustomFieldsPatchSuccess(true);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error.message);
        const { message } = error;
        setFormErrors([...formErrors, { message }]);
      }
    } finally {
      setIsFormLoading(false);
    }
  }

  // Form onSubmit method
  const onSubmit: SubmitHandler<IOrganizationFieldValues> = (data) => {
    async function submitAsync(): Promise<void> {
      if (!profile) {
        await createProfile(data);
      } else {
        await updateProfileForm(data);
      }
    }
    onBeforeSubmit();
    submitAsync();
  };

  // Form onError method
  const onError: SubmitErrorHandler<IProfileFieldValues> = (
    errors: FieldErrorsImpl<IProfileFieldValues>,
  ) => {
    setIsFormLoading(false);
    console.error({ errors });
  };

  // Navigate page when done
  useEffect(() => {
    const navigateToPictureUpload = () => {
      navigate('/register/picture');
    };

    if (
      (isProfilePostSuccess || isProfilePatchSuccess) &&
      (isCustomFieldPostSuccess || isCustomFieldPatchSuccess) &&
      isProfileCustomFieldsPatchSuccess &&
      !isFormLoading
    ) {
      navigateToPictureUpload();
    }
  }, [
    isProfilePostSuccess,
    isCustomFieldPostSuccess,
    isProfileCustomFieldsPatchSuccess,
    isFormLoading,
    isCustomFieldPatchSuccess,
    isProfilePatchSuccess,
    navigate,
  ]);

  // Ready state
  const isReady =
    isSuccessProfiles() &&
    isSuccessUsers() &&
    isSuccessOrganizations() &&
    isSuccessCustomFields() &&
    isSuccessSanityBaseLanguage() &&
    isSuccessSanityOrganization() &&
    !isFormLoading;

  return {
    sanityBaseLanguageProfileFields,
    sanityBaseLanguageLegalNotices,
    sanityBaseLanguageSettings,
    sanityOrganizationPersonalDetailsFields,
    sanityOrganizationProfileFields,
    sanityOrganizationWhiteLabel,
    sanityOrganizationOnboardingFields,
    sanityOrganizationBasicInfo,
    hasResultsOrganizations,
    isSuccessOrganizations,
    isErrorUsers,
    getErrorsUsers,
    isErrorProfiles,
    getErrorsProfiles,
    isErrorCustomFields,
    getErrorsCustomFields,
    profile,
    user,
    localeCode,
    isReady,
    hasErrors,
    formErrors,
    profileErrors,
    customFieldErrors,
    control,
    errors,
    setValue,
    unregister,
    handleSubmit,
    onSubmit,
    onError,
    isSubmitting,
  };
}

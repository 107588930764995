import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormTextFieldTyped } from 'components';
import { IOrganizationFieldValues } from 'forms';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { useValidationErrorBlank } from 'hooks';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrorsImpl,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';

export interface IProfileJobTitleField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfileJobTitleField: React.FunctionComponent<
  IProfileJobTitleField
> = ({
  control,
  errors,
  unregister,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  // Sanity base language hook
  const { user, profile, sanityOrganizationPersonalDetailsFields } =
    useProfileForm({
      getSanityBaseLanguageSilently,
      getSanityOrganizationSilently,
      getProfilesSilently,
    });

  const { getValidationErrorBlank } = useValidationErrorBlank();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const jobTitleInput = baseLanguage.profile_fields.job_title_input_label;

  const defaultValue = profile?.jobTitle ?? user?.jobTitle;

  useEffect(() => {
    if (defaultValue) {
      setValue('jobTitle', defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <FormTextFieldTyped<IOrganizationFieldValues, string>
      name="jobTitle"
      data-cy="forms_ProfileForm_jobTitle-field"
      control={control}
      errors={errors}
      label={
        sanityOrganizationPersonalDetailsFields.job_title?.custom_label ??
        jobTitleInput
      }
      rules={{
        required: sanityOrganizationPersonalDetailsFields.job_title.required,
      }}
      defaultValue={defaultValue}
      requiredErrorMessage={getValidationErrorBlank(jobTitleInput)}
      enabled={sanityOrganizationPersonalDetailsFields.job_title.enabled}
      unregister={unregister}
    />
  );
};

import { FunctionComponent } from 'react';
import { FieldErrorsImpl, Control } from 'react-hook-form';

import { IOrganizationFieldValues } from 'forms';
import { useAppDispatch } from 'store/hooks';
import { toggleModal } from 'store/slices/appSlice';

import { FormCheckbox } from 'components';

export interface ProfileFormCustomLegalCheckboxProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  customCheckboxName: string;
  label: string;
  defaultValue?: string;
}

const ProfileFormGuiderLegalCheckbox: FunctionComponent<
  ProfileFormCustomLegalCheckboxProps
> = ({ control, errors, customCheckboxName, label, defaultValue }) => {
  // Redux
  const dispatch = useAppDispatch();
  // Theme
  const errorColor = 'error.main';
  const errorSx = errors[customCheckboxName]
    ? {
        color: errorColor,
      }
    : undefined;

  return (
    <>
      <FormCheckbox<IOrganizationFieldValues, boolean>
        name={customCheckboxName}
        control={control}
        errors={errors}
        rules={{ required: true }}
        defaultValue={defaultValue}
        label={label}
        labelAction={() => dispatch(toggleModal(customCheckboxName))}
        sx={errors[customCheckboxName] ? errorSx : undefined}
        containerSx={{
          ml: 2,
          color: errors[customCheckboxName] ? errorColor : 'info.main',
          '.MuiTypography-body1': {
            color: errors[customCheckboxName] ? errorColor : 'info.main',
            textDecoration: 'underline',
            '&:hover': {
              color: errors[customCheckboxName] ? 'error.dark' : 'info.dark',
            },
            '&:focus': {
              color: errors[customCheckboxName] ? 'error.dark' : 'info.dark',
            },
          },
        }}
        fullWidth
      />
    </>
  );
};
export default ProfileFormGuiderLegalCheckbox;

import React from 'react';
import { FieldErrorsImpl, Control, UseFormSetValue } from 'react-hook-form';

import { SanityProfileField } from '@guider-global/shared-types';

import { IOrganizationFieldValues } from 'forms';
import CustomSelectFields from './CustomSelectFields';
import CustomFreeTextField from './CustomFreeTextField';

export interface ProfileFormsCustomField extends SanityProfileField {
  defaultValue?: string | string[] | boolean;
}

export interface ProfileFormCustomFieldsProps {
  control: Control<IOrganizationFieldValues>;
  setValue?: UseFormSetValue<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  customFields: ProfileFormsCustomField[];
}

export const ProfileFormsCustomFields: React.FunctionComponent<
  ProfileFormCustomFieldsProps
> = ({ control, errors, customFields, setValue }) => {
  return (
    <>
      {customFields.map(function (field) {
        const {
          free_text_config,
          id,
          multi_select_config,
          options,
          required,
          text,
          type,
          defaultValue,
        } = field;
        switch (type) {
          case 'select':
          case 'multi-select':
            if (multi_select_config && text && options && options.length > 0) {
              return (
                <CustomSelectFields
                  key={id.current}
                  name={id.current}
                  control={control}
                  errors={errors}
                  field={{
                    id,
                    multi_select_config,
                    options,
                    required,
                    text,
                    type,
                    defaultValue,
                  }}
                  setValue={setValue}
                />
              );
            }
            return null;
          case 'free-text':
            if (free_text_config && text) {
              return (
                <CustomFreeTextField
                  key={id.current}
                  name={id.current}
                  control={control}
                  errors={errors}
                  field={{
                    free_text_config,
                    id,
                    required,
                    text,
                    defaultValue,
                  }}
                  setValue={setValue}
                />
              );
            }
            return null;
          default:
            throw new Error('Unknown Sanity Field Type');
        }
      })}
    </>
  );
};

import { FunctionComponent } from 'react';
import { FieldErrorsImpl, Control, UseFormUnregister } from 'react-hook-form';
import { Skeleton } from '@mui/material';

import { IOrganizationFieldValues } from 'forms';
import { FormDropdownTyped } from 'components';

export interface CompleteProfileGenderDropdownProps {
  control: Control<IOrganizationFieldValues>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  label: string;
  value?: string;
  errorMessage: string;
  loading?: boolean;
  required?: boolean;
  enabled?: boolean;
  unregister?: UseFormUnregister<IOrganizationFieldValues>;
  options: {
    label: string;
    value: string;
  }[];
}

export const CompleteProfileGenderDropdown: FunctionComponent<
  CompleteProfileGenderDropdownProps
> = ({
  control,
  errors,
  label,
  value,
  errorMessage,
  loading,
  required,
  enabled,
  unregister,
  options,
}) => {
  if (value || !enabled || !required) return null;
  if (loading) {
    return <Skeleton variant="rectangular" sx={{ height: '56px', mt: 2.5 }} />;
  }

  return (
    <FormDropdownTyped<IOrganizationFieldValues, string>
      name="gender"
      control={control}
      errors={errors}
      rules={{ required }}
      label={label}
      requiredErrorMessage={errorMessage}
      options={options}
      enabled={enabled}
      unregister={unregister}
      defaultValue={value}
    />
  );
};

import React, { useEffect } from 'react';
import { FieldErrorsImpl, Control, UseFormSetValue } from 'react-hook-form';
import { IOrganizationFieldValues } from 'forms';
import { Typography } from '@mui/material';
import { useProfileForm } from 'forms/ProfileForms/ProfileForm/hooks';
import { FormCheckbox } from 'components';
import { useAppDispatch } from 'store/hooks';
import { toggleModal } from 'store/slices/appSlice';

export interface IProfileFormGuiderLegalCheckbox {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IOrganizationFieldValues, any>;
  errors: FieldErrorsImpl<IOrganizationFieldValues>;
  setValue: UseFormSetValue<IOrganizationFieldValues>;
  getSanityBaseLanguageSilently?: boolean;
  getSanityOrganizationSilently?: boolean;
  getProfilesSilently?: boolean;
}

export const ProfileFormGuiderLegalCheckbox: React.FunctionComponent<
  IProfileFormGuiderLegalCheckbox
> = ({
  control,
  errors,
  setValue,
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
}) => {
  const dispatch = useAppDispatch();
  // Theme
  const errorColor = 'error.main';
  const errorSx = errors['privacyPolicy']
    ? {
        color: errorColor,
      }
    : undefined;

  const { profile, sanityBaseLanguageLegalNotices } = useProfileForm({
    getSanityBaseLanguageSilently,
    getSanityOrganizationSilently,
    getProfilesSilently,
  });

  const defaultPrivacyPolicyValue = profile?.privacyPolicy;
  // TODO: Take from profile
  const defaultTermsOfServiceValue = false;

  useEffect(() => {
    if (defaultPrivacyPolicyValue) {
      setValue('privacyPolicy', !!defaultPrivacyPolicyValue);
    }
  }, [defaultPrivacyPolicyValue, setValue]);

  return (
    <>
      <Typography
        sx={{
          mt: 2.5,
        }}
        variant="body1"
        color="text.secondary"
      >
        {`${sanityBaseLanguageLegalNotices.i_agree_to_legals_label} Guider's`}
      </Typography>
      <FormCheckbox<IOrganizationFieldValues, boolean>
        name="privacyPolicy"
        data-cy="forms_ProfileForm_privacyPolicy-checkbox"
        control={control}
        errors={errors}
        rules={{ required: true }}
        defaultValue={defaultPrivacyPolicyValue ? 'true' : 'false'}
        label={
          sanityBaseLanguageLegalNotices.privacy_policy.privacy_policy_label
        }
        labelAction={() => dispatch(toggleModal('privacyPolicy'))}
        sx={errors['privacyPolicy'] ? errorSx : undefined}
        containerSx={{
          ml: 2,
          color: errors['privacyPolicy'] ? errorColor : 'info.main',
          '.MuiTypography-body1': {
            color: errors['privacyPolicy'] ? errorColor : 'info.main',
            textDecoration: 'underline',
            '&:hover': {
              color: errors['privacyPolicy'] ? 'error.dark' : 'info.dark',
            },
            '&:focus': {
              color: errors['privacyPolicy'] ? 'error.dark' : 'info.dark',
            },
          },
        }}
        fullWidth
      />
      <FormCheckbox<IOrganizationFieldValues, boolean>
        name="termsOfService"
        control={control}
        errors={errors}
        rules={{ required: true }}
        defaultValue={defaultTermsOfServiceValue ? 'true' : 'false'}
        label={
          sanityBaseLanguageLegalNotices.terms_of_service.terms_of_service_label
        }
        labelAction={() => dispatch(toggleModal('termsOfService'))}
        sx={errors['termsOfService'] ? errorSx : undefined}
        containerSx={{
          ml: 2,
          color: errors['termsOfService'] ? errorColor : 'info.main',
          '.MuiTypography-body1': {
            color: errors['termsOfService'] ? errorColor : 'info.main',
            textDecoration: 'underline',
            '&:hover': {
              color: errors['termsOfService'] ? 'error.dark' : 'info.dark',
            },
            '&:focus': {
              color: errors['termsOfService'] ? 'error.dark' : 'info.dark',
            },
          },
        }}
        fullWidth
      />
    </>
  );
};

import { ICustomField, IError } from '@guider-global/shared-types';
import {
  IOrganizationFieldValues,
  IProfileFieldValues,
} from 'forms/ProfileForms/ProfileForm/types';
import { updateCustomFields, updateProfile } from 'forms/ProfileForms/utils';
import { useCustomFields, useProfiles } from 'hooks';
import { useEffect, useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  UseProfileEditFormProps,
  UseProfileEditFormResults,
} from '../../types';
import {
  useSanityBaseLanguage,
  useSanityOrganization,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';

const useProfileEditForm = ({
  getSanityBaseLanguageSilently = false,
  getSanityOrganizationSilently = false,
  getProfilesSilently = false,
  getCustomFieldsSilently = false,
}: UseProfileEditFormProps): UseProfileEditFormResults => {
  const organizationSlug: string = getSubDomain();
  // Hooks
  // - Sanity base language
  const {
    isSuccessSanityBaseLanguage,
    isLoadingSanityBaseLanguage,
    getBaseLanguage,
    getErrorsSanityBaseLanguage,
  } = useSanityBaseLanguage({
    getSilently: getSanityBaseLanguageSilently,
  });
  const loadingSanityBaseLanguage = isLoadingSanityBaseLanguage();
  const successSanityBaseLanguage = isSuccessSanityBaseLanguage();
  const errorsSanityBaseLanguage = getErrorsSanityBaseLanguage();
  const sanityBaseLanguageProfileFields = getBaseLanguage().profile_fields;
  const sanityBaseLanguageSettings = getBaseLanguage().settings;
  const localeCode = getBaseLanguage().locale_code.current;
  const errorAlertText =
    sanityBaseLanguageSettings.profile.profile_details.server_error_alert_text;

  // - Sanity organization
  const {
    isSuccessSanityOrganization,
    isLoadingSanityOrganization,
    getErrorsSanityOrganization,
    getOrganization,
  } = useSanityOrganization({
    organizationSlug,
    getSilently: getSanityOrganizationSilently,
  });
  const successSanityOrganization = isSuccessSanityOrganization();
  const loadingSanityOrganization = isLoadingSanityOrganization();
  const errorsSanityOrganization = getErrorsSanityOrganization();
  const sanityOrganizationProfileFields =
    getOrganization().profile_fields ?? [];
  const sanityOrganizationPersonalDetails = getOrganization().personal_details;

  // - Sanity organization programs
  const { refetchPrograms } = useSanityOrganizationPrograms({});

  // - Profiles
  const {
    getProfiles,
    reqProfiles,
    isLoadingProfiles,
    isSuccessProfiles,
    getErrorsProfiles,
  } = useProfiles({
    getSilently: getProfilesSilently,
  });
  const successProfiles = isSuccessProfiles();
  const loadingProfiles = isLoadingProfiles();
  const errorsProfiles = getErrorsProfiles();
  const [profile] = getProfiles();
  const profileId = profile?.id;

  // - CustomFields
  const {
    reqCustomFields,
    isSuccessCustomFields,
    isLoadingCustomFields,
    getErrorsCustomFields,
  } = useCustomFields({
    getSilently: getCustomFieldsSilently,
  });
  const successCustomFields = isSuccessCustomFields();
  const loadingCustomFields = isLoadingCustomFields();
  const errorsCustomFields = getErrorsCustomFields();

  // - Form
  const { control, handleSubmit, formState, unregister, setValue, reset } =
    useForm<IOrganizationFieldValues>({
      mode: 'onChange',
    });
  const { errors, isSubmitting, isValid, isDirty } = formState;
  // Local State
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [editFormErrors, setEditFormErrors] = useState<IError[]>([]);
  const [isErrors, setIsErrors] = useState<boolean>(false);

  const clearSuccess = () => {
    setUpdateSuccess(false);
  };
  const clearIsErrors = () => {
    setIsErrors(false);
  };
  // Effects
  // - Loading
  useEffect(() => {
    if (updating) return;
    if (
      loadingSanityBaseLanguage ||
      loadingSanityOrganization ||
      loadingProfiles ||
      loadingCustomFields
    ) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [
    loadingCustomFields,
    loadingProfiles,
    loadingSanityBaseLanguage,
    loadingSanityOrganization,
    updating,
  ]);
  // - Success
  useEffect(() => {
    if (
      successSanityBaseLanguage &&
      successSanityOrganization &&
      successProfiles &&
      successCustomFields
    ) {
      setSuccess(true);
      return;
    }
    setSuccess(false);
  }, [
    successCustomFields,
    successProfiles,
    successSanityBaseLanguage,
    successSanityOrganization,
  ]);

  useEffect(() => {
    if (editFormErrors.length > 0) {
      setIsErrors(true);
    }
  }, [editFormErrors.length]);

  // Events

  const updateEditProfileForm = async (data: IOrganizationFieldValues) => {
    try {
      if (organizationSlug && profile) {
        const {
          dateOfBirth,
          country,
          firstName,
          gender,
          jobTitle,
          lastName,
          townOrCity,
          privacyPolicy,
          pronouns,
          linkedInUrl,
          termsOfService,
          // react-hook-form values
          keepValues,
          // customFields values
          ...customFields
        } = data;
        const updateProfileResults = await updateProfile({
          reqProfiles,
          data: {
            dateOfBirth,
            country,
            firstName,
            gender,
            jobTitle,
            lastName,
            townOrCity,
            privacyPolicy,
            pronouns,
            linkedInUrl,
          },
          profileId,
        });

        if (!updateProfileResults.success) {
          if (updateProfileResults.errors) {
            setEditFormErrors([
              { message: errorAlertText },
              ...updateProfileResults.errors,
            ]);

            throw new Error('could not update profile');
          }
          setEditFormErrors([{ message: errorAlertText }]);

          throw new Error('could not update profile');
        }

        const updateCustomFieldsResults = await updateCustomFields({
          customFieldsList: profile.organizationFields as ICustomField[],
          data: customFields,
          profileId,
          organizationSlug,
          reqCustomFields,
        });

        if (!updateCustomFieldsResults.success) {
          if (updateCustomFieldsResults.errors) {
            setEditFormErrors([
              { message: errorAlertText },
              ...updateCustomFieldsResults.errors,
            ]);
            throw new Error('could not update profile');
          }
          setEditFormErrors([{ message: errorAlertText }]);
          throw new Error('could not update profile');
        }
        const [profilesResult] = await Promise.all([
          reqProfiles({ url: `/profiles/${profileId}` }),
          reqCustomFields({ url: '/customFields' }),
        ]);

        if (profilesResult.data?.[0]) {
          await refetchPrograms(organizationSlug, localeCode);
        }

        setUpdateSuccess(true);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.warn(error.message);
      }
      setLoading(false);
    } finally {
      setSuccess(true);
      setLoading(false);
      setUpdating(false);
    }
  };

  const onBeforeSubmit = () => {
    setEditFormErrors([]);
    setUpdating(true);
    setLoading(true);
  };

  const onSubmit: SubmitHandler<IOrganizationFieldValues> = (data) => {
    const submitAsync = async () => {
      await updateEditProfileForm(data);
    };

    onBeforeSubmit();
    submitAsync();
    reset({ keepValues: true });
  };

  const onError: SubmitErrorHandler<IProfileFieldValues> = (
    errors: FieldErrorsImpl<IProfileFieldValues>,
  ) => {
    setLoading(false);
    console.warn({ errors });
  };

  return {
    // React Hook Form
    control,
    handleSubmit,
    errors,
    isSubmitting,
    unregister,
    setValue,
    // Sanity Content
    sanityBaseLanguageProfileFields,
    sanityBaseLanguageSettings,
    localeCode,
    // Profile
    profile,
    // Organization
    sanityOrganizationProfileFields,
    sanityOrganizationPersonalDetails,
    // Effects
    loading,
    success,
    updateSuccess,
    clearSuccess,
    // Events
    onSubmit,
    onError,
    // Hook Errors
    errorsSanityBaseLanguage,
    errorsSanityOrganization,
    errorsProfiles,
    errorsCustomFields,
    // Errors/Validation
    editFormErrors,
    isErrors,
    clearIsErrors,
    isValid,
    isDirty,
  };
};

export default useProfileEditForm;
